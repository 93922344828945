import React, { useState, useEffect } from "react";
import { TabStateType } from "../chart-navigator";
import { Term, Terms } from "../../api/open-api/generated/types";
import Accordion from "@mui/material/Accordion";
import { StyledAccordionDetails, StyledAccordionSummary } from "../legend/accordion-styles";
import { ExpandMore } from "@mui/icons-material";
import { logger } from "@optum-ai-charts/chart-components";
import { SearchType } from "../../providers/search/search-state";

type ContraindicationsProps = {
  id: string;
  title?: string;
  groups: TabStateType;
  terms: Terms;
  onCollapseExpand: (element: string, expand: boolean) => void;
  tierClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, tierId: string, terms: Terms) => void;
  termClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, term: Term, type: SearchType) => void;
  searchType: SearchType;
};

function KeywordTier({
  id,
  title,
  groups,
  terms,
  onCollapseExpand,
  tierClick,
  termClick,
  searchType,
}: ContraindicationsProps): React.JSX.Element {
  const [accordionUI, setAccordionUI] = useState<React.JSX.Element>(<></>);

  useEffect(() => {
    if (terms.length) {
      logger.log(`Render ${terms.length} terms in the guide for ${id}`);
      const contraindicationTerms = terms.map((term) => (
        <div className="keywords-entry-view">
          <button
            type="button"
            key={`${id}_${term.id}`}
            onClick={(e) => termClick(e, term, searchType)}
            className="keyword-entry"
          >
            {term.text}
          </button>
        </div>
      ));
      setAccordionUI(
        <Accordion
          expanded={groups.get(id)}
          key={`tier_${id}_`}
          className="legend-section"
          style={{ margin: "0px" }}
          onChange={(e, expanded) => {
            onCollapseExpand(id, expanded);
          }}
        >
          <StyledAccordionSummary expandIcon={<ExpandMore />}>
            <button className="keyword-header" type="button" onClick={(e) => tierClick(e, title || id, terms)}>
              {title || id.charAt(0).toUpperCase() + id.slice(1)}
            </button>
          </StyledAccordionSummary>
          <StyledAccordionDetails>{contraindicationTerms}</StyledAccordionDetails>
        </Accordion>,
      );
    }
  }, [id, title, terms, groups, onCollapseExpand, tierClick, termClick, searchType]);

  return <>{accordionUI}</>;
}

export default KeywordTier;
