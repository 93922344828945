/**
 * @file Parent pane on right of app that houses the Current Page, All Pages, and Hits components
 */
import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import TabPanel from "../../utils/core/tab-panel";
import * as API from "../../api/requests";
import SearchResults from "../../search/search-results";
import { useSearchContext } from "../../providers/search";
import KeywordsGuide from "../keywords-guide";
// import Legend from "../legend/legend";
// import KeyIcon from '@mui/icons-material/Key';
// import { SURVEY_URL } from "../../config";

import "../../assets/css/main.css";
import "../../assets/css/chart-navigator.css";
import { Terms, Tiers } from "../../api/open-api/generated/types";
import { HedisDispatcher } from "../../providers/hedis";
import {
  setTiersMap,
  setExclusions as setExclusionTerms,
  setContraindications as setContraindicationTerms,
} from "../../providers/hedis/hedis-actions";
import { logger } from "@optum-ai-charts/chart-components";
import Help from "../help/help";

const TAB_PANEL = {
  SEARCH: 0,
  KEYWORDS: 1,
  LEGEND: 2,
};

function a11yProps(index: number): {
  id: string;
  "aria-controls": string;
  "min-width": string;
} {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tab-panel-${index}`,
    "min-width": "22px",
  };
}

// for managing the expanded/collapsed state of the accordions
export type TabStateType = Map<string, boolean>;

export type ChartNavigatorProps = {
  measure: string;
  closeSidebar: () => void;
};

function ChartNavigator({ measure, closeSidebar }: ChartNavigatorProps): React.JSX.Element {
  const hedisDispatch = useContext(HedisDispatcher);
  const [tabSelect, setTabSelect] = React.useState(TAB_PANEL.SEARCH);
  const [keywordGroups, setKeywordGroups] = useState<TabStateType>(new Map());
  const [tiers, setTiers] = useState<Tiers>([]);
  const [inclusions, setInclusions] = useState<Terms>([]);
  const [exclusions, setExclusions] = useState<Terms>([]);
  const [contraindications, setContraindications] = useState<Terms>([]);
  const [fetchFailed, setFetchFailed] = useState<boolean>(false);

  // keeping track of searches done from "outside" of this component
  const { searchCount } = useSearchContext();
  const lastSearchCount = useRef<number>(0);

  // Effect that is run once on instantiation of component, that fetches the keyword terms and tiers. This component
  // will keep this state, and pass it down to children as needed.
  useEffect(() => {
    Promise.all([API.getKeywordTiers(measure), API.getKeywordTerms(measure)])
      .then(([tiersResponse, termsResponse]) => {
        logger.log("GET tiers and terms:");
        logger.log(tiersResponse.data);
        logger.log(termsResponse.data);

        // Process the tiers first
        const allTiers = tiersResponse.data;
        const sortedTiers = allTiers.sort((t1, t2) => t1.order - t2.order);
        setTiers(sortedTiers);

        // Set up a tiers map that maps tier names to the terms under that tier, and set it on the HEDIS
        // provider. This could probably use a re-work. This entire loading could be done in HEDIS loader, and maybe
        // all tier and term processing could be done here off of that HEDIS state variable.
        const tiersMap = new Map<string, string[]>();
        sortedTiers.forEach((tier) => tiersMap.set(tier.name, []));

        // The groups are states that controls the tiers accordion UI
        const groups: TabStateType = new Map();
        for (const tier of allTiers) {
          groups.set(tier.name, false);
        }
        groups.set("unacceptableTerms", false);
        groups.set("contraindications", false);
        setKeywordGroups(groups);

        // Now process the terms, separating into term types to pass down to the keywords guide
        const allTerms = termsResponse.data;

        const exclusionTerms = allTerms.filter((term) => term.type === "contraindication");
        setExclusions(exclusionTerms);
        hedisDispatch(setExclusionTerms(new Set(exclusionTerms.map((term) => term.text))));

        const contraindicationTerms = allTerms.filter((term) => term.type === "exclusion");
        setContraindications(contraindicationTerms);
        hedisDispatch(setContraindicationTerms(new Set(contraindicationTerms.map((term) => term.text))));

        // Add all inclusions to the tier map and set the map on the HEDIS provider
        const includedTerms = allTerms.filter((term) => term.type === "inclusion");
        setInclusions(includedTerms);
        includedTerms.forEach((term) => {
          const tier = tiersMap.get(term.tier);
          if (tier) {
            tier.push(term.text);
          } else {
            logger.warn(`Keyword term "${term.text}" is in tier "${term.tier}", but tier was not found`);
          }
        });
        hedisDispatch(setTiersMap(tiersMap));
      })
      .catch(() => {
        setFetchFailed(true);
      });
  }, [measure, hedisDispatch]);

  useEffect(() => {
    // Any time a new search is done, make the SEARCH tab active. e.g. when a user clicks on a keyword term in the
    // KEYWORDS tab.
    if (tabSelect !== 0 && lastSearchCount.current !== searchCount) {
      setTabSelect(TAB_PANEL.SEARCH);
    }
    lastSearchCount.current = searchCount;
  }, [searchCount, tabSelect]);

  const handleKeywordsAccordion = (tierName: string, isExpanded: boolean): void => {
    setKeywordGroups(new Map(keywordGroups.set(tierName, isExpanded)));
  };

  return (
    <Box
      className="side-bar"
      sx={{
        borderLeft: 1,
        position: "relative",
        top: "2px",
        bgcolor: "base.neutral0",
      }}
    >
      <div className="close-button" onClick={closeSidebar}>
        x <span className="close-sidebar">Close</span>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabSelect} onChange={(e, activeTab) => setTabSelect(activeTab)} aria-label="basic tabs example">
          <Tab
            style={{ width: "50%" }}
            label="Search"
            sx={{ textTransform: "none", fontWeight: "bold", fontSize: "16px" }}
            {...a11yProps(TAB_PANEL.SEARCH)}
          />
          <Tab
            style={{ width: "50%" }}
            label="Keywords"
            sx={{ textTransform: "none", fontWeight: "bold", fontSize: "16px" }}
            {...a11yProps(TAB_PANEL.KEYWORDS)}
          />
          {/* <Tab label="Legend" {...a11yProps(TAB_PANEL.LEGEND)} /> */}
        </Tabs>
      </Box>
      <TabPanel style={{ flex: "1 1", overflow: "auto" }} value={tabSelect} index={TAB_PANEL.SEARCH}>
        <SearchResults />
      </TabPanel>
      <TabPanel style={{ flex: "1 1", overflow: "auto" }} value={tabSelect} index={TAB_PANEL.KEYWORDS}>
        {fetchFailed && <div>Keywords are not available for this chart. Please continue abstraction.</div>}
        {!fetchFailed && (
          <KeywordsGuide
            groups={keywordGroups}
            tiers={tiers}
            inclusions={inclusions}
            exclusions={exclusions}
            contraindications={contraindications}
            onCollapseExpand={handleKeywordsAccordion}
          />
        )}
      </TabPanel>
      <Box sx={{ marginTop: "20px" }}>
        <Help />
      </Box>
      {/* <Box>
        <Button
          className="feedback-button"
          variant="text"
          onClick={() => window.open(SURVEY_URL)}
          style={{ textTransform: "none", height: "30px" }}
          color="primary130"
        >
          <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>Feedback? Click here</Typography>
        </Button>
      </Box> */}
    </Box>
  );
}

export default ChartNavigator;
