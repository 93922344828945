import { SearchIndex } from "../../api/open-api/generated/types";
import { AutoCompletion, SearchResult } from "./types";
import { SearchRunnerOptions } from "./search-runner";

export abstract class SearchSource {
  public id: string;
  public order: number;
  public engine: string;
  public index: any;
  constructor(spec: SearchIndex) {
    this.id = spec.id;
    this.order = spec.order;
    this.engine = spec.engine;
    this.index = this.deserialize(spec.index!);
  }

  get source(): string {
    return this.id;
  }

  protected abstract deserialize(serializedIndex: string): any;
  public abstract autocomplete(query: string): AutoCompletion[];
  public abstract runQuery(query: string, options: SearchRunnerOptions): SearchResult[];
  // public abstract autoCompletions(query: string): AutoCompletion[];
}
