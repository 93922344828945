import { CHART_ACTION } from '@optum-ai-charts/chart-components';
import { SearchType } from "../providers/search/search-state";

export const APP_SEARCH_ID = {
  COMMON: 0,
  MEMBER_INFO: 1,
  UNACCEPTABLE: 2,
  CONTRAINDICATION: 3,
  // COMMON: GetNextId(),
  // MEMBER_INFO: GetNextId(),
  // UNACCEPTABLE: GetNextId(),
  // CONTRAINDICATION: GetNextId(),
};

export const APP_SEARCH_STYLE_NAME = {
  COMMON: 'Common',
  MEMBER_INFO: 'Member Information',
  UNACCEPTABLE: 'Unacceptable Term',
  CONTRAINDICATION: 'Contraindication',
};

type StyleDefinition = {
  fill: string;
  stroke?: string;
  strokeWidth?: number;
};

type StyleSpecification = {
  name: string;
  definition: {
    active?: StyleDefinition;
    inactive: StyleDefinition;
  };
};

type StyleAddAction = StyleSpecification & {
  type: number;
};

const SEARCH_STYLE_DEFINITIONS = new Map<number, StyleSpecification>([
  [
    APP_SEARCH_ID.COMMON,
    {
      name: APP_SEARCH_STYLE_NAME.COMMON,
      definition: {
        inactive: { fill: '#eed202' },
      },
    },
  ],
  [
    APP_SEARCH_ID.MEMBER_INFO,
    {
      name: APP_SEARCH_STYLE_NAME.MEMBER_INFO,
      definition: { inactive: { fill: '#FAFF00' } },
    },
  ],
  [
    APP_SEARCH_ID.CONTRAINDICATION,
    {
      name: APP_SEARCH_STYLE_NAME.CONTRAINDICATION,
      definition: {
        inactive: {
          fill: 'rgba(0,0,0,0)',
          strokeWidth: 3,
          stroke: '#8400D9',
        },
      },
    },
  ],
  [
    APP_SEARCH_ID.UNACCEPTABLE,
    {
      name: APP_SEARCH_STYLE_NAME.UNACCEPTABLE,
      definition: {
        inactive: {
          fill: 'rgba(0,0,0,0)',
          strokeWidth: 3,
          stroke: '#fc5371',
        },
      },
    },
  ],
]);

export function AddStyleAction(styleId: number): StyleAddAction {
  if (!SEARCH_STYLE_DEFINITIONS.has(styleId)) {
    throw Error(`No such search style id: ${styleId}`);
  }
  const styleDefinition = SEARCH_STYLE_DEFINITIONS.get(styleId);
  return {
    type: CHART_ACTION.ADD_STYLE,
    name: styleDefinition!.name,
    definition: styleDefinition!.definition,
  };
}

export function SearchTypeToStyleName(type: SearchType): string {
  switch (type) {
    case SearchType.EXCLUSION:
      return APP_SEARCH_STYLE_NAME.CONTRAINDICATION;
    case SearchType.CONTRAINDICATION:
      return APP_SEARCH_STYLE_NAME.UNACCEPTABLE;
    default:
      return APP_SEARCH_STYLE_NAME.COMMON;
  }
}

export function TierNameToStyleName(tierName: string): string {
  switch (tierName.toLowerCase()) {
    case "exclusions":
      return APP_SEARCH_STYLE_NAME.CONTRAINDICATION;
    case "unacceptable terms":
      return APP_SEARCH_STYLE_NAME.UNACCEPTABLE;
    default:
      return APP_SEARCH_STYLE_NAME.COMMON;
  }
}
