import React, { useContext, useState, useEffect, useCallback, useRef } from "react";
import { Grid, Typography } from "@mui/material";
import { CHART_ACTION, ChartContext, ChartDispatcher } from "@optum-ai-charts/chart-components";
import { useSearchContext, SearchDispatcher } from "../providers/search";
import TextMatchResult from "./text-match-result";
import { SearchResult } from "../utils/search";
import GenerateNotFoundMessage from "./utils";

export default function SearchResults(): React.JSX.Element {
  const chartDispatch = useContext(ChartDispatcher);
  const searchDispatcher = useContext(SearchDispatcher);
  const { searchCount, searchType, searchTier, searchTerms, searchResults } = useSearchContext();
  // @ts-ignore
  const { chartId, chartCount, pageCurrent } = useContext(ChartContext);
  const [activeSearchResult, setActiveSearchResult] = useState<SearchResult | null>(null);
  const [notFoundMsg, setNotFoundMsg] = useState<string>("");
  const lastRenderedSearch = useRef<number>(0);
  const itemsRef = useRef<HTMLElement[]>([]);

  const jumpToResult = useCallback(
    (result: SearchResult) => {
      setActiveSearchResult(result);
      // @ts-ignore
      chartDispatch({
        type: CHART_ACTION.PAGE_JUMP,
        page: result.page,
      });
    },
    [chartDispatch],
  );

  // The "main" effect, when results on the search provider change, get the results and clear the provider
  useEffect(() => {
    if (searchCount !== lastRenderedSearch.current) {
      lastRenderedSearch.current = searchCount;
      if (!searchResults.length) {
        setNotFoundMsg(GenerateNotFoundMessage(searchType, searchTier, searchTerms));
      } else {
        // jump the chart to the page of the first search result, convenience for abstractor
        jumpToResult(searchResults[0]);
        setNotFoundMsg("");
      }
    }
  }, [searchCount, searchResults, chartDispatch, searchDispatcher, searchTier, searchTerms, searchType, jumpToResult]);

  return (
    <>
      {searchResults.length === 0 && (
        <Typography sx={{ color: "#BF1616", fontSize: 16, fontStyle: "italic" }}>{notFoundMsg}</Typography>
      )}
      <Grid container>
        {searchResults.length > 0 ? (
          <span className="search-result-count">Search results({searchResults.length})</span>
        ) : (
          ""
        )}
        {searchResults.map((result, idx) => {
          const cardStyle: React.CSSProperties = { display: "block", width: "100%", marginBottom: "2px" };
          if (result === activeSearchResult) {
            cardStyle.border = "3px solid #1976d2";
          }
          return (
            <TextMatchResult
              key={`result-${result.page}-${result.span}`}
              result={result}
              chartId={chartId}
              currentPage={pageCurrent}
              chartCount={chartCount}
              style={cardStyle}
              onClickResult={jumpToResult}
              ref={(el: HTMLDivElement) => {
                itemsRef.current[idx] = el;
              }}
            />
          );
        })}
      </Grid>
    </>
  );
}
