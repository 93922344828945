/**
 * @file The search bar component at the top of the app
 * @author tpederson
 */
import React, { Dispatch, useCallback, useContext, useEffect, useRef, useState } from "react";

// 3rd party
// import Fuse from 'fuse.js';
import { useCombobox, UseComboboxStateChange } from "downshift";

// CHC chart components package
import { CHART_ACTION, ChartDispatcher, logger } from "@optum-ai-charts/chart-components";

// providers
import { IndexStatus, SearchDispatcher, useSearchContext } from "../../providers/search";
// import { KeywordsContext } from '../../providers/keywords-context';
import { HedisAction, HedisDispatcher, MEMBER_MATCH_STATUS, useHedisContext } from "../../providers/hedis";

// hooks
import useKeyboardShortcuts from "../../hooks/use-keyboard-shortcuts";

// assets
import { SearchIcon } from "@uitk/react-icons";
import inclusionCheck from "../../assets/icons/inclusion.svg";
import modelQueryHits from "../../assets/icons/reviewed-hits.svg";
import exclusionX from "../../assets/icons/exclusion.svg";
import searchIcon from "../../assets/icons/search.svg";
import closeXIcon from "../../assets/icons/close-x.svg";

// css
import "./chart-search.css";
import { AddStyleAction, APP_SEARCH_ID, APP_SEARCH_STYLE_NAME, TierNameToStyleName } from "../../search/search-styles";
import { IndexTypeExt, SearchResult, SearchRunner, SearchSource } from "../../utils/search";
import { newSearch } from "../../providers/search/search-actions";
import { SearchType } from "../../providers/search/search-state";
import { SEARCH_SORT_OPTIONS } from "../../utils/search/search-runner";
import { IndexType } from "../../api/open-api/generated/types";
import { AutoCompletion } from "../../utils/search/types";

interface ChartSearchProps {
  openSearchResults: () => void;
}

function ChartSearch({ openSearchResults }: ChartSearchProps): React.JSX.Element {
  /*
        Uses the search context to set the search query on the context. The context will compute the search results.
        This component doesn't care about the results.
     */
  const chartDispatch = useContext<Dispatch<any>>(ChartDispatcher);
  const hedisDispatch = useContext<Dispatch<HedisAction>>(HedisDispatcher);
  const searchDispatch = useContext(SearchDispatcher);

  const { tiersMap, exclusions, contraindications, memberMatchStatus } = useHedisContext();
  const { sources, indexStatus } = useSearchContext();
  const [searchItem, setSearchItem] = useState<AutoCompletion | null>(null);
  const [, /*searchResultsId*/ setSearchResultsId] = useState(-1);
  const [items, setItems] = useState<AutoCompletion[]>([]);
  const [searchBarEmpty, setSearchBarEmpty] = useState(true);
  const searchInputEl = useRef<HTMLInputElement>(null);
  const [searchRunner, setSearchRunner] = useState<SearchRunner | null>(null);

  // The auto-complete mechanics will be handled using 3rd party library useCombobox (downshift)
  // See: https://github.com/downshift-js/downshift/tree/master/src/hooks/useCombobox
  const [keywordCompletions, setKeywordCompletions] = useState<AutoCompletion[]>([]);

  useEffect(() => {
    if (sources.length) {
      const runner = new SearchRunner();
      sources.forEach((source: SearchSource) => {
        runner.addSource(source);
      });
      setSearchRunner(runner);
    }
  }, [sources]);

  useEffect(() => {
    chartDispatch(AddStyleAction(APP_SEARCH_ID.COMMON));
    chartDispatch(AddStyleAction(APP_SEARCH_ID.CONTRAINDICATION));
    chartDispatch(AddStyleAction(APP_SEARCH_ID.UNACCEPTABLE));
    // Get an ID for search results. This ID will be used to delete old results and highlight the new ones each time a
    // search is performed.
    setSearchResultsId(APP_SEARCH_ID.COMMON);

    // Set the main search info on the hedis context (should be moved to redux). This allows keywords guide to perform
    // searches using the same style and id. Re-using same id will overwrite highlights on each new search.
    // hedisDispatch(setSearchInfo(APP_SEARCH_ID.COMMON, APP_SEARCH_STYLE_NAME.COMMON));
  }, [chartDispatch, hedisDispatch]);

  const doSearch = useCallback(
    (searchType: SearchType, terms: string[], results: SearchResult[], tierName: string | null = null) => {
      let styleName = APP_SEARCH_STYLE_NAME.COMMON;
      if (searchType === SearchType.TIER && tierName) {
        styleName = TierNameToStyleName(tierName);
      } else if (searchType === SearchType.KEYWORD) {
        if (exclusions.has(terms[0])) {
          styleName = APP_SEARCH_STYLE_NAME.CONTRAINDICATION;
        }
        if (contraindications.has(terms[0])) {
          styleName = APP_SEARCH_STYLE_NAME.UNACCEPTABLE;
        }
      }
      searchDispatch(newSearch(searchType, terms, results));
      // @ts-ignore
      // tell the chart viewer to annotate the search results
      chartDispatch({
        type: CHART_ACTION.ANNOTATE_CHART,
        annotations: results,
        styleName,
      });
    },
    [searchDispatch, chartDispatch, exclusions, contraindications],
  );

  /**
   * Handle user selecting an item from the search dropdown
   * @param selection - An object with match type, and keywords array
   */
  const handleSuggestionSelected = (selection: UseComboboxStateChange<AutoCompletion>): void => {
    if (!selection.selectedItem) {
      // setPagedMatches(new Map());
      setSearchItem(null);
      // searchInputEl.current.focus();
      // setSearchQuery('');
      return;
    }
    setSearchItem(selection.selectedItem);
    if (searchRunner) {
      let searchResults = [];
      const source = selection.selectedItem.source;
      switch (source) {
        case IndexType.Tiers:
          const tierName = selection.selectedItem.text.match(/All terms in (.*)/i);
          if (tierName && tierName.length > 1) {
            const tierTerms = tiersMap.get(tierName[1]);
            logger.debug(`get all terms for tier ${selection.selectedItem.text}`);
            logger.debug(tierTerms);
            if (tierTerms) {
              searchResults = searchRunner.runQueries(tierTerms, {
                indexName: IndexType.Document,
                exactMatches: true,
              });
              doSearch(SearchType.TIER, [selection.selectedItem.text], searchResults, tierName.toString());
            }
          }
          break;
        case IndexType.Terms:
          searchResults = searchRunner.runQuery(selection.selectedItem.text, {
            indexName: IndexType.Document,
            exactMatches: true,
          });
          doSearch(SearchType.KEYWORD, [selection.selectedItem.text], searchResults);
          break;
        case IndexType.Document:
          searchResults = searchRunner.runQuery(selection.selectedItem.text, {
            indexName: IndexType.Document,
            exactMatches: true,
          });
          doSearch(SearchType.DOCUMENT_HIT, [selection.selectedItem.text], searchResults);
          break;
        case IndexType.ModelQuery:
          searchResults = searchRunner.runQuery(selection.selectedItem.text, {
            indexName: IndexType.ModelQuery,
            sort: SEARCH_SORT_OPTIONS.RANKED_ORDER,
          });
          doSearch(SearchType.PRE_COMPUTED, [selection.selectedItem.text], searchResults);
          break;
      }
    }
  };

  /**
   * Handles when user types substring and hits return instead of selecting an item in dropdown
   * @param e - the event
   */
  function handleFreeForm(e: React.SyntheticEvent): void {
    e.preventDefault(); // this is in a form, prevent submit of form so browser doesn't reload page
    const term = searchInputEl?.current?.value;
    if (term && searchRunner) {
      const searchResults = searchRunner.runQuery(term, { indexName: IndexType.Document, exactMatches: false });
      doSearch(SearchType.RAW, [term], searchResults);
    }
  }

  /**
   * Callback that is passed to drop-down to return string representation of selection object
   * @param completionObject - Object with type of MATCH_TYPE and keywords, an array of string
   * @return {string|*}
   */
  const completionObjectToString = (completionObject: AutoCompletion | null): string => {
    return completionObject?.text || "";
  };

  /**
   * Helper that returns the icon asset to display on left-hand of dropdown result items
   * @return {*}
   * @param completion
   */
  const getIcon = (completion: AutoCompletion): string => {
    logger.debug(`get icon for ${completion.source}`);
    switch (completion.source) {
      case IndexType.ModelQuery:
        return modelQueryHits;
      case IndexType.Terms:
        if (exclusions.has(completion.text) || contraindications.has(completion.text)) {
          return exclusionX;
        } else {
          return inclusionCheck;
        }
      case IndexType.Tiers:
        return inclusionCheck;
      case IndexType.Document:
      default:
        return searchIcon;
    }
  };

  /*
    This is the monster function to configure and control the downshift dropdown 3rd party component. See docs.
 */
  const {
    isOpen,
    openMenu,
    closeMenu,
    selectItem,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    setInputValue,
  } = useCombobox({
    items,
    selectedItem: searchItem,
    itemToString: completionObjectToString,
    onSelectedItemChange: handleSuggestionSelected,
    onInputValueChange: ({ inputValue }) => {
      setSearchBarEmpty(inputValue === "");

      // require at least 2 characters to get a match
      if (!inputValue || inputValue.trim().length < 2) {
        setKeywordCompletions([]);
        return;
      }
      if (!searchRunner) {
        return;
      }

      const autoCompletions: AutoCompletion[] = searchRunner.runAutocompletions(inputValue, {
        uniqueHits: false,
        sort: SEARCH_SORT_OPTIONS.AUTO_COMPLETE_ORDER,
      });
      logger.debug(autoCompletions);

      // A quirk of the fuse.js library, the list of completions must be a super-set of items. If items is not
      // set here, clicking on an item in the dropdown menu won't work.
      if (!autoCompletions.length) {
        logger.debug(`set not found message on autocomplete`);
        autoCompletions.push({
          source: IndexTypeExt.NotFound,
          text: `no results matching for "${inputValue}"`,
        });
      }
      setItems(autoCompletions);
      setKeywordCompletions(autoCompletions);
    },
  });

  const handleMetaF = useCallback((e: KeyboardEvent) => {
    const { keyCode, altKey } = e;
    if (altKey && keyCode === 70 && searchInputEl.current) {
      searchInputEl.current.focus();
      return true;
    }
    return false;
  }, []);

  useKeyboardShortcuts(handleMetaF);

  function getPlaceholderText(): string {
    if (memberMatchStatus !== MEMBER_MATCH_STATUS.CONFIRMED) {
      return "Please complete member verification first";
    }
    switch (indexStatus) {
      case IndexStatus.LOADING:
        return "Loading chart text, please wait...";
      case IndexStatus.LOADED:
        return "Search for keywords";
      case IndexStatus.ERROR:
        return "An error occurred indexing the chart";
      default:
        logger.warn(`unknown index status = ${indexStatus}`);
        return "Status Unknown";
    }
  }

  return (
    <div className="chart-nav-search">
      <form
        onSubmit={handleFreeForm}
        className={`${memberMatchStatus !== MEMBER_MATCH_STATUS.CONFIRMED ? "dimmed" : ""}`}
      >
        <input
          tabIndex={0}
          {...getInputProps({
            disabled: memberMatchStatus !== MEMBER_MATCH_STATUS.CONFIRMED || indexStatus !== IndexStatus.LOADED,
            placeholder: getPlaceholderText(),
            spellCheck: "false",
            onFocus: (e) => {
              if (!isOpen && e.target.value.length > 1) {
                setInputValue(e.target.value);
                openMenu();
              }
            },
            onKeyDown: (e) => {
              // If user hits enter without selecting an item in the dropdown, bypass downshift, close
              // the menu, and do the "free-form" search by allowing enter to submit the form
              if (e.key === "Enter" && highlightedIndex === -1) {
                // lack of typescript definition, see https://github.com/downshift-js/downshift/issues/734
                (e.nativeEvent as any).preventDownshiftDefault = true;
                closeMenu();
                openSearchResults();
              }
            },
            ref: searchInputEl,
          })}
        />
        <span className="magnifier-icon">
          <SearchIcon />
        </span>
        {!searchBarEmpty && (
          <span
            role="button"
            className="clear-search-icon"
            onClick={() => {
              selectItem(null);
            }}
          >
            <img src={closeXIcon} alt="close search" />
          </span>
        )}
      </form>
      <ul {...getMenuProps()} className="search-form-keyword-suggestions">
        {isOpen &&
          keywordCompletions.map((completion, keywordIdx) => {
            const itemKey = `${completion.source}${keywordIdx}`;
            return (
              <li
                className="search-form-keyword-suggestion"
                style={
                  highlightedIndex === keywordIdx
                    ? { backgroundColor: "#bde4ff", cursor: "pointer" }
                    : { cursor: "pointer" }
                }
                key={itemKey}
                {...getItemProps({
                  item: completion,
                  index: keywordIdx,
                })}
              >
                {(() => {
                  /* eslint-disable no-fallthrough */
                  switch (completion.source) {
                    case IndexTypeExt.NotFound:
                      logger.debug(`type NotFound, ${completion.text}`);
                      return (
                        <div onClick={openSearchResults} className="tier">
                          <div className="search-form-no-match">
                            <span className="no-match-keyword">{completion.text}</span>
                          </div>
                        </div>
                      );
                    case IndexType.Tiers:
                    case IndexType.Terms:
                    // case AutoCompletionType.Exclusion:
                    case IndexType.Document:
                      logger.debug(`type inclusion, ${completion.source} , ${completion.text}`);
                      return (
                        <div onClick={openSearchResults} className="tier">
                          <img src={getIcon(completion)} alt="inclusion" />
                          <div>
                            {/*{completion.text && <div className="tier-suggestion">{completion.text}</div>}*/}
                            <div className="tier-keywords">{completion.text}</div>
                          </div>
                        </div>
                      );
                    case IndexType.ModelQuery:
                      return (
                        <div className="tier">
                          <img src={getIcon(completion)} alt="model-query" />
                          <div className="tier-keywords">{completion.text}</div>
                        </div>
                      );
                    // case MATCH_TYPE.INCLUSION_TIER:
                    //   // logger.debug(`type inclusion tier, ${completion.text}`);
                    //   return (
                    //     <div className="tier">
                    //       <img src={getIcon(completion.type)} alt="inclusion" />
                    //       <div>
                    //         {completion.text && <div className="tier-suggestion">{completion.text}</div>}
                    //         <div className="tier-keywords">{completion.keywords.map((k) => k.primary).join(', ')}</div>
                    //       </div>
                    //     </div>
                    //   );
                    default:
                      logger.warn(`unknown match type: ${completion.source}`);
                      return <></>;
                  }
                  /* eslint-enable no-fallthrough */
                })()}
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default ChartSearch;
