import React, { useState, useContext, useEffect, useCallback } from "react";

// optum ui toolkit
import { Notification } from "@uitk/react";

// components
// import { TitleBar } from "../../components/title-bar";
import Main from "../../components/main";
import ViewerMain from "../../components/viewer-main";
import PdfLoader from "./pdf-loader";
import HedisChartLoader from "./crux-loader";
import ViewerLoader from "./viewer-loader";
import LoadingScreen from "./loading-screen";
import { LOADING_STATES } from "./loading-states";
import * as API from "../../api/requests";
import { Document, MemberMatch, Member } from "../../api/open-api/generated/types";

import {
  HedisDispatcher,
  confirmMemberMatch,
  setMeasure,
  setPatientInfo,
  setUnknownPages,
  setSuspiciousPages,
  setToken,
  setDisplayPdf,
} from "../../providers/hedis";

function DocumentLoader(): React.JSX.Element {
  const hedisDispatch = useContext(HedisDispatcher);
  const [loadingState, setLoadingState] = useState(LOADING_STATES.INITIAL);
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token") || "";
  const viewer_only = queryParams.get("viewer_only") || false;
  const [document, setDocument] = useState<Document | null>(null);

  const parseMemberInfo = useCallback(
    (memberMatchInfo: MemberMatch | undefined, memberInfo: Member | undefined) => {
      // Member match processing, maybe move this into its own callback
      if (memberMatchInfo) {
        if (
          memberMatchInfo.status.toLowerCase() !== "confirmed" &&
          process.env.REACT_APP_SKIP_MEMBER_MATCH !== "true"
        ) {
          // record unknown and suspicious pages to the HEDIS state
          if (memberMatchInfo.unknown_pages) {
            hedisDispatch(
              setUnknownPages(
                memberMatchInfo.unknown_pages.map((page: number) => {
                  return { page, done: false };
                }),
              ),
            );
          }
          if (memberMatchInfo.suspicious_pages) {
            hedisDispatch(
              setSuspiciousPages(
                memberMatchInfo.suspicious_pages.map((page: number) => {
                  return { page, done: false };
                }),
              ),
            );
          }
        } else {
          hedisDispatch(confirmMemberMatch());
        }
      }
      if (memberInfo) {
        hedisDispatch(setPatientInfo(memberInfo));
      }
    },
    [hedisDispatch],
  );

  const fetchDocument = useCallback(async () => {
    if (loadingState !== LOADING_STATES.INITIAL) {
      return;
    }

    // Try to load the document from the backend
    setLoadingState(LOADING_STATES.LOADING);
    let response;
    try {
      response = await API.getDocumentUsingJwt();
    } catch (e) {
      setLoadingState(LOADING_STATES.ERROR);
      return;
    }
    // set the token into the state for later use
    const documentResponse = response!.data;
    setDocument(documentResponse);
    hedisDispatch(setToken(token));
    hedisDispatch(setMeasure(documentResponse.measures[0]));

    hedisDispatch(setDisplayPdf(documentResponse.display_as_pdf));
    if (!documentResponse.display_as_pdf) {
      parseMemberInfo(documentResponse.member_match_info, documentResponse.member);
    }
    setLoadingState(LOADING_STATES.LOADED);
  }, [token, hedisDispatch, loadingState, parseMemberInfo]);

  // Instantiate the HEDIS API immediately so the loader can make calls
  useEffect(() => {
    API.instantiateApi(token);
    fetchDocument();
  }, [token, fetchDocument]);

  // Render based on loading state, then based on the query params and pdf flag in the document, if loading was successful
  switch (loadingState) {
    case LOADING_STATES.ERROR:
      return (
        <div className="vertical-flex-center flex-grow">
          <Notification variant={"error"}>
            There is no work image item available. Please proceed to reject the work item.
          </Notification>
        </div>
      );
    case LOADING_STATES.LOADED:
      if (viewer_only) {
        return (
          <ViewerLoader token={token}>
            <ViewerMain />
          </ViewerLoader>
        );
      } else if (document!.display_as_pdf) {
        return <PdfLoader documentId={document!.id} />;
      } else {
        return (
          <HedisChartLoader token={token} document={document!}>
            {/* <TitleBar /> */}
            <Main />
          </HedisChartLoader>
        );
      }
    case LOADING_STATES.LOADING:
    default:
      return <LoadingScreen />;
  }
}

export default DocumentLoader;
