import { SearchSource } from "./search-source";
import { SearchIndex } from "../../api/open-api/generated/types";
import { LunrSource } from "./lunr/lunr-source";
import { AutoCompletion, SearchResult } from "./types";
import { FuseSource } from "./fuse-source";
import { logger } from "@optum-ai-charts/chart-components";

const DEFAULT_AUTO_COMPLETION_PER_INDEX_MAX = 14;
const DEFAULT_AUTO_COMPLETION_MAX = 14;

export const enum SEARCH_SORT_OPTIONS {
  NO_SORTING,
  DOCUMENT_ORDER,
  AUTO_COMPLETE_ORDER,
  RANKED_ORDER,
}

export interface SearchRunnerOptions {
  indexName?: string;
  sort?: SEARCH_SORT_OPTIONS;
  perIndexLimit?: number;
  totalLimit?: number;
  uniqueHits?: boolean;
  exactMatches?: boolean;
}

export class SearchRunner {
  private sources: SearchSource[];
  private readonly defaultOptions: SearchRunnerOptions;
  private sourceOrder: Map<string, number>;

  static makeSource(source: SearchIndex): SearchSource | null {
    if (!source || !source.index) {
      logger.warn(`NULL search index`);
      return null;
    }
    switch (source.engine.toLowerCase()) {
      case "lunr":
        return new LunrSource(source);
      case "fuse":
        return new FuseSource(source);
      default:
        logger.warn(`Cannot make search source for engine: ${source.engine}`);
        return null;
    }
  }
  constructor() {
    this.sources = [];
    this.sourceOrder = new Map();
    this.defaultOptions = {
      sort: SEARCH_SORT_OPTIONS.DOCUMENT_ORDER,
      perIndexLimit: +(process.env.REACT_APP_AUTOCOMPLETION_PER_INDEX_MAX || DEFAULT_AUTO_COMPLETION_PER_INDEX_MAX),
      totalLimit: +(process.env.REACT_APP_AUTOCOMPLETION_MAX || DEFAULT_AUTO_COMPLETION_MAX),
      uniqueHits: false,
      exactMatches: false,
    };
  }

  public addSource(source: SearchSource): void {
    this.sources.push(source);
    this.sourceOrder.set(source.id, source.order);
  }

  public runAutocompletions(query: string, options: SearchRunnerOptions = {}): AutoCompletion[] {
    const allOptions = {
      ...this.defaultOptions,
      ...options,
    };
    let results = this.getAutocompletions(query, allOptions);
    results = this.deduplicateResults(results, (item) => `${item.text}_${item.source}`);
    results = this.sortAutoCompletions(results);
    return results.slice(0, allOptions.totalLimit);
  }

  public runQuery(query: string, options: SearchRunnerOptions = {}): SearchResult[] {
    const allOptions = {
      ...this.defaultOptions,
      ...options,
    };
    let results = this.run(query, allOptions);
    // TODO: should this de-dup using page and span?
    // results = this.deduplicateResults(results, (item) => `${item.text}_${item.source}`);
    results = this.sortResults(results, allOptions.sort!);
    return results;
  }

  public runQueries(queries: string[], options: SearchRunnerOptions = {}): SearchResult[] {
    let allResults: SearchResult[] = [];
    const allOptions = {
      ...this.defaultOptions,
      ...options,
    };
    for (const query of queries) {
      const queryResults = this.run(query, allOptions);
      allResults.push(...queryResults);
    }
    // TODO: should this de-dup using page and span?
    // allResults = this.deduplicateResults(allResults, (item) => `${item.text}_${item.source}`);
    allResults = this.sortResults(allResults, allOptions.sort!);
    return allResults;
  }

  /**
   *
   * @param query
   * @param options
   * @protected
   */
  protected getAutocompletions(query: string, options: SearchRunnerOptions): AutoCompletion[] {
    // Searches *all* indices
    // Will enforce autocompletion limits so as not to over-populate the dropdown
    let indexesToSearch = this.sources;
    if (options.indexName) {
      indexesToSearch = indexesToSearch.filter((index) => index.source === options.indexName);
    }
    const results: AutoCompletion[] = [];
    for (const index of indexesToSearch) {
      const singleIndexResults = index.autocomplete(query).slice(0, options.perIndexLimit);
      results.push(...singleIndexResults);
    }
    return results;
  }

  protected run(query: string, options: SearchRunnerOptions): SearchResult[] {
    let indexesToSearch = this.sources;
    if (options.indexName) {
      indexesToSearch = indexesToSearch.filter((index) => index.source === options.indexName);
    }
    const results: SearchResult[] = [];
    for (const index of indexesToSearch) {
      const singleIndexResults = index.runQuery(query, options);
      results.push(...singleIndexResults);
    }
    return results;
  }

  protected deduplicateResults<T>(results: T[], getKey: (item: T) => string): T[] {
    const uniqueResultsMap = new Map<string, T>();
    for (const result of results) {
      const key = getKey(result);
      if (!uniqueResultsMap.has(key)) {
        uniqueResultsMap.set(key, result);
      }
    }

    return Array.from(uniqueResultsMap.values());
  }

  protected sortInDocumentOrder(result1: SearchResult, result2: SearchResult): number {
    if (result1.page !== result2.page) {
      return result1.page! - result2.page!;
    }
    return result1.span![0] - result2.span![0];
  }

  protected sortInAutoCompletionOrder(result1: AutoCompletion, result2: AutoCompletion): number {
    const o1 = this.sourceOrder.get(result1.source);
    const o2 = this.sourceOrder.get(result2.source);
    if (o1 !== o2) {
      logger.debug(`sort ${result1.text} ${result2.text} by order (${o1}, ${o2})`);
      return o1! - o2!;
    }
    if (result1.text < result2.text) {
      return -1;
    } else if (result1.text > result2.text) {
      return 1;
    }
    return 0;
  }

  protected sortInRankedOrder(result1: SearchResult, result2: SearchResult): number {
    const o1 = this.sourceOrder.get(result1.source);
    const o2 = this.sourceOrder.get(result2.source);
    if (o1 !== o2) {
      logger.debug(`sort ${result1.text} ${result2.text} by order (${o1}, ${o2})`);
      return o1! - o2!;
    }
    return (result2.score || 0.0) - (result1.score || 0.0);
  }

  protected sortAutoCompletions(completions: AutoCompletion[]): AutoCompletion[] {
    return completions.sort(this.sortInAutoCompletionOrder.bind(this));
  }

  protected sortResults(results: SearchResult[], sortAlgorithm: SEARCH_SORT_OPTIONS): SearchResult[] {
    switch (sortAlgorithm) {
      case SEARCH_SORT_OPTIONS.DOCUMENT_ORDER:
        return results.sort(this.sortInDocumentOrder.bind(this));
      case SEARCH_SORT_OPTIONS.RANKED_ORDER:
        return results.sort(this.sortInRankedOrder.bind(this));
      case SEARCH_SORT_OPTIONS.NO_SORTING:
      default:
        return results;
    }
  }
}
