import React, { useState, useEffect, useCallback, useContext, ReactNode } from "react";
import { ChartDispatcher, CHART_FEATURE, CHART_ACTION, logger } from "@optum-ai-charts/chart-components";
import { HedisDispatcher, setMeasure, setPatientInfo, setToken } from "../../providers/hedis";
import { SearchDispatcher, setIndexStatus, IndexStatus, addIndex } from "../../providers/search";
import { API_URL_PREFIXES } from "../../api/proxy-prefixes";
import LoadingScreen from "./loading-screen";
import * as API from "../../api/requests";
import { PageDimensions } from "../../api/open-api/generated/types";

const LOADING_STATES = {
  LOADING: 0,
  LOADED: 1,
  ERROR: 2,
};

type ErrorInfo = {
  status: number;
  statusText: string;
  description: string;
};

type ChartLoaderProps = {
  children?: ReactNode;
  token: string;
};

function ViewerLoader({ token, children }: ChartLoaderProps): React.JSX.Element {
  const hedisDispatch = useContext(HedisDispatcher);
  const chartDispatch = useContext(ChartDispatcher);
  const searchDispatch = useContext(SearchDispatcher);
  const [loadingState, setLoadingState] = useState(LOADING_STATES.LOADING);
  const [errorInfo, setErrorInfo] = useState<ErrorInfo | null>(null);
  // const [sessionId, setSessionId] = useState<string>('');
  const [firstPages, setFirstPages] = useState<PageDimensions>([]);
  const [documentId, setDocumentId] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(-1);
  const [originalPageCount, setOriginalPageCount] = useState<number>(-1);

  const loadHedisChart = useCallback(() => {
    API.getDocumentUsingJwt()
      .then((response) => {
        logger.log("got session");
        logger.log(response.data);
        const sessionId = response.data.id;
        if (process.env.REACT_APP_OBFUSCATE_TOKEN === "true") {
          window.history.replaceState({}, "", `/codingsessions/${sessionId}`);
        }
        // set the token into the state for later use
        hedisDispatch(setToken(token));
        hedisDispatch(setMeasure(response.data.measures[0]));
        hedisDispatch(setPatientInfo(response.data.member!));
        const document = response.data;
        setDocumentId(document.id);
        setPageCount(document.total_page_count);
        setOriginalPageCount(document.original_page_count);
        searchDispatch(setIndexStatus(IndexStatus.LOADING));
        API.getDocumentSearchIndexes(documentId)
          .then((indexResponse) => {
            indexResponse.data.indexes.forEach((index) => searchDispatch(addIndex(index)));
            searchDispatch(setIndexStatus(IndexStatus.LOADED));
            logger.log(indexResponse);
          })
          .catch((error) => {
            logger.warn(`Search disabled, error getting indexes: ${error}`);
            searchDispatch(setIndexStatus(IndexStatus.ERROR));
          });
      })
      .catch((err) => {
        logger.error(err);
        setErrorInfo({
          status: err.status,
          statusText: err.statusText,
          description: "Failed to get coding session",
        });
        setLoadingState(LOADING_STATES.ERROR);
      });
  }, [token, documentId, hedisDispatch, searchDispatch]);

  useEffect(() => {
    // We want to configure the chart-components module ASAP so it has the proxies and can make API calls
    // @ts-ignore
    chartDispatch({
      type: CHART_ACTION.CONFIGURE,
      proxies: {
        s3: API_URL_PREFIXES.PREFIX_S3_URL,
        api: API_URL_PREFIXES.PREFIX_HEDIS_API_URL,
      },
      token,
      // eslint-disable-next-line no-bitwise
      features: CHART_FEATURE.FIT_ON_CHART_LOAD | CHART_FEATURE.PAGE_LOADING_SPINNER | CHART_FEATURE.DEBUGGER,
    });

    // Kick off the chart-loading process
    loadHedisChart();
  }, [token, loadHedisChart, chartDispatch]);

  useEffect(() => {
    if (firstPages.length && documentId.length) {
      // calculate the average page dimensions from the first pages loaded
      let valid = 0;
      let totalWidth = 0;
      let totalHeight = 0;

      for (let i = 0; i < firstPages.length; i++) {
        if (firstPages[i].width > 0) {
          valid++;
          totalWidth += firstPages[i].width;
          totalHeight += firstPages[i].height;
        }
      }
      const pageDimensions = {
        width: Math.round(totalWidth / valid),
        height: Math.round(totalHeight / valid),
      };
      logger.log(`loading chart viewer with dimensions (${pageDimensions.width},${pageDimensions.height}`);
      // @ts-ignore
      chartDispatch({
        type: CHART_ACTION.LOAD_CHART,
        chartId: documentId,
        chartCount: pageCount,
        originalPageCount: originalPageCount,
        chartData: null,
        chartInfo: {
          ocrStatus: 1,
        },
        pageDimensions,
        preloadImageUrls: true,
      });
      setLoadingState(LOADING_STATES.LOADED);
    }
  }, [documentId, firstPages, token, hedisDispatch, pageCount, chartDispatch, originalPageCount]);

  useEffect(() => {
    if (documentId.length && pageCount > 0) {
      API.getPageDimensions(documentId, {
        start: 0,
        limit: Math.min(pageCount, 3),
      })
        .then((pageDimensions) => {
          logger.log("page dimensions");
          logger.log(pageDimensions.data);
          setFirstPages(pageDimensions.data);
        })
        .catch((error) => {
          setErrorInfo({
            status: error.status,
            statusText: error.statusText,
            description: "Failed to get chart dimensions",
          });
          setLoadingState(LOADING_STATES.ERROR);
        });
    }
  }, [documentId, pageCount]);

  switch (loadingState) {
    case LOADING_STATES.ERROR:
      return (
        <div className="chart-container vertical-flex-center">
          <h1>{errorInfo?.description || "There was an error"}</h1>
          <h2>
            {errorInfo?.status} : {errorInfo?.statusText}
          </h2>
        </div>
      );
    case LOADING_STATES.LOADED:
      return <>{children}</>;
    case LOADING_STATES.LOADING:
    default:
      return <LoadingScreen />;
  }
}

export default ViewerLoader;
