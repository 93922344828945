import { Review } from "../../components/member-match/types";
import { Member } from "../../api/open-api/generated/types";

export const enum HEDIS_ACTION {
  SET_CHASE_ID,
  SET_MEASURE,
  SET_TOKEN,
  SET_UNKNOWN_PAGES,
  SET_SUSPICIOUS_PAGES,
  CONFIRM_MEMBER_MATCH,
  CONFIRM_PAGE,
  CONFIRM_UNKNOWN_PAGE,
  SET_PATIENT_INFO,
  SET_HEALTH_PLAN,
  SET_SEARCH_INFO,
  SET_INCLUSION_INFO,
  SET_EXCLUSION_INFO,
  SET_CONTRAINDICATION_INFO,
  SET_TIERS_MAP,
  SET_EXCLUSIONS,
  SET_CONTRAINDICATIONS,
  SET_DISPLAY_PDF,
}

export interface SetChaseIdAction {
  type: HEDIS_ACTION.SET_CHASE_ID;
  payload: {
    chaseId: string;
  };
}

export const setChaseId = (chaseId: string): SetChaseIdAction => {
  return {
    type: HEDIS_ACTION.SET_CHASE_ID,
    payload: { chaseId },
  };
};

export interface SetMeasureAction {
  type: HEDIS_ACTION.SET_MEASURE;
  payload: {
    measure: string;
  };
}

export const setMeasure = (measure: string): SetMeasureAction => {
  return {
    type: HEDIS_ACTION.SET_MEASURE,
    payload: { measure },
  };
};

export interface SetTokenAction {
  type: HEDIS_ACTION.SET_TOKEN;
  payload: {
    token: string;
  };
}

export const setToken = (token: string): SetTokenAction => {
  return {
    type: HEDIS_ACTION.SET_TOKEN,
    payload: { token },
  };
};

export interface ConfirmMemberMatchAction {
  type: HEDIS_ACTION.CONFIRM_MEMBER_MATCH;
}

export const confirmMemberMatch = (): ConfirmMemberMatchAction => {
  return {
    type: HEDIS_ACTION.CONFIRM_MEMBER_MATCH,
  };
};

export interface ConfirmPageAction {
  type: HEDIS_ACTION.CONFIRM_PAGE;
  payload: {
    page: number;
  };
}

export const confirmPage = (page: number): ConfirmPageAction => {
  return {
    type: HEDIS_ACTION.CONFIRM_PAGE,
    payload: { page },
  };
};

export interface ConfirmUnknownPageAction {
  type: HEDIS_ACTION.CONFIRM_UNKNOWN_PAGE;
  payload: {
    page: number;
  };
}

export const confirmUnknownPage = (page: number): ConfirmUnknownPageAction => {
  return {
    type: HEDIS_ACTION.CONFIRM_UNKNOWN_PAGE,
    payload: { page },
  };
};

export interface SetUnknownPagesAction {
  type: HEDIS_ACTION.SET_UNKNOWN_PAGES;
  payload: {
    unknownPages: Review[];
  };
}

export const setUnknownPages = (unknownPages: Review[]): SetUnknownPagesAction => {
  return {
    type: HEDIS_ACTION.SET_UNKNOWN_PAGES,
    payload: { unknownPages },
  };
};

export interface SetSuspiciousPagesAction {
  type: HEDIS_ACTION.SET_SUSPICIOUS_PAGES;
  payload: {
    suspiciousPages: Review[];
  };
}

export const setSuspiciousPages = (suspiciousPages: Review[]): SetSuspiciousPagesAction => {
  return {
    type: HEDIS_ACTION.SET_SUSPICIOUS_PAGES,
    payload: { suspiciousPages },
  };
};

export interface SetSearchInfoAction {
  type: HEDIS_ACTION.SET_SEARCH_INFO;
  payload: {
    id: number;
    styleName: string;
  };
}

export const setPatientInfo = (info: Member): SetPatientInfoAction => {
  return {
    type: HEDIS_ACTION.SET_PATIENT_INFO,
    payload: { info },
  };
};

export interface SetHealthPlanAction {
  type: HEDIS_ACTION.SET_HEALTH_PLAN;
  payload: {
    healthPlan: string;
  };
}

export const setHealthPlan = (healthPlan: string): SetHealthPlanAction => {
  return {
    type: HEDIS_ACTION.SET_HEALTH_PLAN,
    payload: { healthPlan },
  };
};

export interface SetPatientInfoAction {
  type: HEDIS_ACTION.SET_PATIENT_INFO;
  payload: {
    info: Member;
  };
}

export const setSearchInfo = (id: number, styleName: string): SetSearchInfoAction => {
  return {
    type: HEDIS_ACTION.SET_SEARCH_INFO,
    payload: { id, styleName },
  };
};

export interface SetTiersMapAction {
  type: HEDIS_ACTION.SET_TIERS_MAP;
  payload: {
    tiers: Map<string, string[]>;
  };
}

export const setTiersMap = (tiers: Map<string, string[]>): SetTiersMapAction => {
  return {
    type: HEDIS_ACTION.SET_TIERS_MAP,
    payload: { tiers },
  };
};

export interface SetExclusionsAction {
  type: HEDIS_ACTION.SET_EXCLUSIONS;
  payload: {
    exclusions: Set<string>;
  };
}

export const setExclusions = (exclusions: Set<string>): SetExclusionsAction => {
  return {
    type: HEDIS_ACTION.SET_EXCLUSIONS,
    payload: { exclusions },
  };
};

export interface SetContraindicationsAction {
  type: HEDIS_ACTION.SET_CONTRAINDICATIONS;
  payload: {
    contraindications: Set<string>;
  };
}

export const setContraindications = (contraindications: Set<string>): SetContraindicationsAction => {
  return {
    type: HEDIS_ACTION.SET_CONTRAINDICATIONS,
    payload: { contraindications },
  };
};

export interface SetDisplayPdf {
  type: HEDIS_ACTION.SET_DISPLAY_PDF;
  payload: {
    display_as_pdf: boolean;
  };
}

export const setDisplayPdf = (display_as_pdf: boolean): SetDisplayPdf => {
  return {
    type: HEDIS_ACTION.SET_DISPLAY_PDF,
    payload: { display_as_pdf },
  };
};

export type HedisAction =
  | SetChaseIdAction
  | SetMeasureAction
  | SetTokenAction
  | ConfirmMemberMatchAction
  | ConfirmPageAction
  | ConfirmUnknownPageAction
  | SetPatientInfoAction
  | SetHealthPlanAction
  | SetUnknownPagesAction
  | SetSuspiciousPagesAction
  | SetSearchInfoAction
  | SetTiersMapAction
  | SetExclusionsAction
  | SetContraindicationsAction
  | SetDisplayPdf;
