/**
 * @file Topmost application component of the React app.
 * @author tpederson
 */
import React from "react";
import { ThemeProvider, createTheme, alpha, getContrastRatio } from "@mui/material/styles";
import { Toolkit } from "@uitk/react";

// CHC chart module
import { ChartProvider, logger } from "@optum-ai-charts/chart-components";

// components
import { DocumentLoader } from "./components/loaders";

// providers
import { SearchProvider } from "./providers/search";
import { HedisProvider } from "./providers/hedis";

// styles
import "./App.scss";
import { PaletteColor } from "@mui/material";
import { instantiateApi } from "./api/requests";

declare module "@mui/material/styles" {
  interface Palette {
    base: PaletteColor;
    primary130: PaletteColor;
    neutral150: PaletteColor;
  }
  interface PaletteOptions {
    base: PaletteColor;
    primary130: PaletteColor;
    neutral150: PaletteColor;
  }

  interface TypographyVariants {
    XSmall: React.CSSProperties;
    SmallCaps: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    XSmall?: React.CSSProperties;
    SmallCaps?: React.CSSProperties;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    base: true;
    primary130: true;
    neutral150: true;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    XSmall: true;
    SmallCaps: true;
  }
}

const baseColor = "#F4F6F9";
const baseMain = alpha(baseColor, 0.7);

const neutral150Color = "#0F0F59";
const neutral150Main = alpha(neutral150Color, 0.7);

const primary130Color = "#0E75A5";
const primary130Main = alpha(primary130Color, 0.7);

// I am new to Material UI. This is a very crude beginning to try to set up and use styles that Vitaly called out in Figma.
const theme = createTheme({
  palette: {
    base: {
      main: baseMain,
      light: alpha(baseColor, 0.5),
      dark: alpha(baseColor, 0.9),
      contrastText: getContrastRatio(baseMain, "#fff") > 4.5 ? "#fff" : "#111",
    },
    neutral150: {
      main: neutral150Main,
      light: alpha(neutral150Color, 0.5),
      dark: alpha(neutral150Color, 0.9),
      contrastText: getContrastRatio(neutral150Main, "#fff") > 4.5 ? "#fff" : "#111",
    },
    // primary 130 is /base/primary130 in the figma spec, but it doesn't seem to work within base
    primary130: {
      main: primary130Main,
      light: alpha(primary130Color, 0.5),
      dark: alpha(primary130Color, 0.9),
      contrastText: getContrastRatio(primary130Main, "#fff") > 4.5 ? "#fff" : "#111",
    },
    primary: {
      main: "#0F0F59",
    },
    secondary: {
      main: "#626D8A",
    },
  },
  typography: {
    XSmall: {
      fontSize: 11,
    },
    SmallCaps: {
      fontSize: 12,
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  },
});

function App(): React.JSX.Element {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token") || "";

  // Instantiate the HEDIS API immediately so the loader can make calls
  instantiateApi(token);

  logger.log(`REACT_APP_APP_NAME = ${process.env.REACT_APP_APP_NAME}`);
  logger.log(`REACT_APP_TEST = ${process.env.REACT_APP_TEST}`);
  logger.log(`REACT_APP_AUTO_PAGE_REVIEW_MS = ${process.env.REACT_APP_AUTO_PAGE_REVIEW_MS}`);
  logger.log(`REACT_APP_MAX_PAGE_REVIEW = ${process.env.REACT_APP_MAX_PAGE_REVIEW}`);
  logger.log(`REACT_APP_MAX_CARDS_WITH_PREVIEW = ${process.env.REACT_APP_MAX_CARDS_WITH_PREVIEW}`);
  logger.log(`REACT_APP_SKIP_MEMBER_MATCH = ${process.env.REACT_APP_SKIP_MEMBER_MATCH}`);
  logger.log(`REACT_APP_AUTO_PAGE_REVIEW_MS = ${process.env.REACT_APP_CONFIRM_MEMBER_SHORTCUT}`);
  logger.log(`REACT_APP_DEBUG_PAGINATED_GETS = ${process.env.REACT_APP_DEBUG_PAGINATED_GETS}`);

  return (
    <Toolkit>
      <div className="chart-viewer-app">
        <ChartProvider>
          <HedisProvider>
            <SearchProvider>
              <ThemeProvider theme={theme}>
                <DocumentLoader />
              </ThemeProvider>
            </SearchProvider>
          </HedisProvider>
        </ChartProvider>
      </div>
    </Toolkit>
  );
}

export default App;
