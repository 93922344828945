import { createResponseValidator, ValidationResult } from "openapi-runtime-validator";
import hasStatusError from "./hasStatusError";
import openApiSchema from "../api/open-api/generated/hedis-spec-v3.json";
import { API_URL_PREFIXES } from "./proxy-prefixes";

const preparePathname = (path: string): string => {
  // exclude internal Proxy paths
  return path.replace("/api/", "/").replace(`${API_URL_PREFIXES.PREFIX_HEDIS_API_URL}/`, "/");
};

// exclude images from validation
const skipValidation = (path: string): boolean => {
  return path.match(new RegExp(`${API_URL_PREFIXES.PREFIX_S3_URL}/`)) !== null;
};

const onValidate = ({ response, validationError, status, method, path }: ValidationResult): void => {
  if (validationError?.message) {
    const errorMessage = validationError?.message || "";
    const fullErrorMessage = `[BE] response error in schema
      CODE: ${validationError.code},
      METHOD: ${method},
      URL: ${response.url}
      STATUS: ${response.status}
      operationId: ${validationError?.operationId}

      VALIDATION ERROR: ${errorMessage}
    `;

    // Always console log it
    // eslint-disable-next-line no-console
    console.error(fullErrorMessage);

    // Throw exception if configured to do so
    if (process.env.REACT_APP_THROW_ON_VALIDATION_ERRORS === "true") {
      throw Error(
        `[BE] schema validation error: <${validationError.code}> ${method}: ${validationError?.operationId || path}`,
      );
    }
  }
  const statusError = hasStatusError(status);
  switch (path) {
    default:
      if (statusError) {
        // eslint-disable-next-line no-console
        console.error(statusError);
      }
      break;
  }
};

const responseValidator = createResponseValidator({
  openApiSchema,
  preparePathname,
  skipValidation,
  onValidate,
});

export default responseValidator;
