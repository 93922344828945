/**
 * @file Display searchable keywords related to the measure being worked
 */

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import { TabStateType } from "../chart-navigator";
import { IndexType, Term, Terms, Tiers } from "../../api/open-api/generated/types";
import { SearchRunner } from "../../utils/search";
import { newSearch } from "../../providers/search/search-actions";
import { SearchType } from "../../providers/search/search-state";
import { CHART_ACTION, ChartDispatcher, logger } from "@optum-ai-charts/chart-components";
import { SearchDispatcher, useSearchContext } from "../../providers/search";
import KeywordTier from "./keywords-tier";

import InfoCircle from "../../assets/icons/info-circle.svg";

import "../../assets/css/chart-navigator.css";
import "./keywords-guide.css";
import { useHedisContext } from "../../providers/hedis";
import { SearchTypeToStyleName, TierNameToStyleName } from "../../search/search-styles";

type KeywordsGuideProps = {
  groups: TabStateType;
  tiers: Tiers;
  inclusions: Terms;
  exclusions: Terms;
  contraindications: Terms;
  onCollapseExpand: (element: string, expand: boolean) => void;
};

function KeywordsGuide({
  groups,
  tiers,
  inclusions,
  exclusions,
  contraindications,
  onCollapseExpand,
}: KeywordsGuideProps): React.JSX.Element {
  const { sources } = useSearchContext();
  const { measure } = useHedisContext();
  const [searchRunner, setSearchRunner] = useState<SearchRunner | null>(null);
  const searchDispatch = useContext(SearchDispatcher);
  const chartDispatch = useContext(ChartDispatcher);

  useEffect(() => {
    if (sources.length) {
      const runner = new SearchRunner();
      sources.forEach((source) => {
        runner.addSource(source);
      });
      setSearchRunner(runner);
      logger.log(`Created a search runner with ${sources.length} sources`);
    }
  }, [sources]);

  const handleTermClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, term: Term, type: SearchType): void => {
      event.stopPropagation();
      event.preventDefault();
      if (searchRunner) {
        const results = searchRunner.runQuery(term.text, { indexName: IndexType.Document, exactMatches: true });
        searchDispatch(newSearch(type, [term.text], results));
        // @ts-ignore
        // tell the chart viewer to annotate the search results
        chartDispatch({
          type: CHART_ACTION.ANNOTATE_CHART,
          annotations: results,
          styleName: SearchTypeToStyleName(type),
        });
      }
    },
    [searchRunner, searchDispatch, chartDispatch],
  );

  const handleTierClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, tierId: string, terms: Terms): void => {
      event.stopPropagation();
      event.preventDefault();
      if (searchRunner) {
        const queryStrings = terms.map((term) => term.text);
        const results = searchRunner.runQueries(queryStrings, { indexName: IndexType.Document, exactMatches: true });
        searchDispatch(newSearch(SearchType.TIER, queryStrings, results, tierId));
        // @ts-ignore
        // tell the chart viewer to annotate the search results
        chartDispatch({
          type: CHART_ACTION.ANNOTATE_CHART,
          annotations: results,
          styleName: TierNameToStyleName(tierId),
        });
      }
    },
    [searchRunner, searchDispatch, chartDispatch],
  );

  logger.debug(`render keywords guide with ${tiers.length} tiers and ${inclusions.length} inclusions`);
  logger.debug(`render ${exclusions.length} exclusions and ${contraindications.length} contraindications`);

  return (
    <div className="keywords-guide side-bar-sub-component">
      <h3>
        <span>{measure} Keywords</span>
        <span
          className="chart-nav-tooltip-icon"
          data-tip="Perform a quick search<br />by clicking a keyword"
          data-html="true"
          data-background-color="#3f5159"
          data-class="chart-nav-tooltip"
        >
          <img src={InfoCircle} alt="unverified pages tooltip" />
        </span>
        <Tooltip />
      </h3>

      <div className="keywords-guide-pane">
        {tiers.map((tier) => {
          logger.debug(`map to ${inclusions.filter((inclusion) => inclusion.tier === tier.id).length} terms`);
          return (
            <KeywordTier
              title={tier.name}
              key={tier.id}
              id={tier.id}
              groups={groups}
              terms={inclusions.filter((inclusion) => inclusion.tier === tier.name)}
              onCollapseExpand={onCollapseExpand}
              tierClick={handleTierClick}
              termClick={handleTermClick}
              searchType={SearchType.KEYWORD}
            />
          );
        })}
        <KeywordTier
          id="exclusions"
          groups={groups}
          terms={exclusions}
          onCollapseExpand={onCollapseExpand}
          tierClick={handleTierClick}
          termClick={handleTermClick}
          searchType={SearchType.EXCLUSION}
        />
        <KeywordTier
          id="contraindications"
          title="Unacceptable Terms"
          groups={groups}
          terms={contraindications}
          onCollapseExpand={onCollapseExpand}
          tierClick={handleTierClick}
          termClick={handleTermClick}
          searchType={SearchType.CONTRAINDICATION}
        />
      </div>
    </div>
  );
}

export default KeywordsGuide;
