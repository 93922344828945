/**
 * @file Small horizontal pane below title pane that displays verification status and critical details like name and DOB
 */

import React, { useEffect, useState } from "react";
import {
  Link,
  // Alert,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useHedisContext,
  // MEMBER_MATCH_STATUS
} from "../../providers/hedis";
import "./chart-details.css";
import { ErrorIcon } from "@uitk/react-icons";

interface ChartDetailsProps {
  toggleSidebar: () => void;
}

function ChartDetails({ toggleSidebar }: ChartDetailsProps): React.JSX.Element {
  const { memberMatchStatus, patientInfo, unknownPages } = useHedisContext();
  const [unknownPagesNum /* setUnknownPagesNum */] = useState<number>(unknownPages.length);
  // const [showMatchAlert, setShowMatchAlert] = useState(true);
  // const [memberIsConfirmed, setMemberIsConfirmed] = useState(false);

  const toggleUnknownPages = (): void => {
    toggleSidebar();
  };

  useEffect(() => {
    //   // effect to set a timeout to hide the green confirmed banner after the member match has been confirmed
    //   const confirmed = memberMatchStatus === MEMBER_MATCH_STATUS.CONFIRMED;
    //   if (confirmed) {
    //     setTimeout(() => setShowMatchAlert(false), 1000 * 10);
    //   }
    //   setMemberIsConfirmed(confirmed);
  }, [memberMatchStatus]);

  return (
    <div>
      {patientInfo !== null && (
        <>
          <div className="chart-details">
            <div>
              <span className="chart-details-title">Member Name: </span>
              <span className="member-info">
                {patientInfo.lastname}, {patientInfo.firstname}
              </span>
            </div>
            <div>
              <span className="chart-details-title">Date of Birth: </span>
              <span className="member-info">{patientInfo.dob}</span>
            </div>
            <div>
              <span className="chart-details-title">Member ID: </span>
              <span className="member-info">{patientInfo.id}</span>
            </div>
            <div>
              <span className="chart-details-title">Gender: </span>
              <span className="member-info">{patientInfo.gender}</span>
            </div>
            {memberMatchStatus === 3 && unknownPages.length > 0 ? (
              <div className="unknownpages-details">
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: "black",
                        backgroundColor: "white",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: '24px',
                        cursor: "pointer",
                        padding: "16px",
                        width: '265px',
                        border: '1px solid #6D6F70'
                      },
                    },
                  }}
                  arrow
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [-104, 0],
                          },
                        },
                      ],
                    },
                  }}
                  title={"Unknown pages have been identified, Please review carefully."}
                >
                  <span className="error-icon">
                    <ErrorIcon height={20} width={20} fill="#C40000" />
                  </span>
                </Tooltip>
                <Link onClick={toggleUnknownPages}>
                  <Typography
                    sx={{
                      color: "#C40000",
                      fontSize: "14px",
                      lineHeight: "24px",
                      fontWeight: 700,
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    Unknown Pages ({unknownPagesNum})
                  </Typography>
                </Link>
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}

export default ChartDetails;
