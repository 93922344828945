import { SearchType } from "../providers/search/search-state";
import { logger } from "@optum-ai-charts/chart-components";

export default function GenerateNotFoundMessage(searchType: SearchType, tier: string, terms: string[]): string {
  let message = "";
  switch (searchType) {
    case SearchType.RAW:
      message = `"${terms[0]}" not found in the document`;
      break;
    case SearchType.KEYWORD:
    case SearchType.CONTRAINDICATION:
    case SearchType.EXCLUSION:
      if (terms.length === 1) {
        message = `${searchType} '${terms[0]}' not found`;
      } else {
        message = `None of the ${terms.length} ${searchType}s found`;
      }
      break;
    case SearchType.TIER:
      message = `None of the terms in tier ${tier} were found`;
      break;
    // pre-computed and hits should always be found
    case SearchType.PRE_COMPUTED:
    case SearchType.DOCUMENT_HIT:
    default:
      logger.warn(`Bad search logic: ${searchType}`);
      break;
  }
  return message;
}
