// import React, { useState, useEffect /*, useContext*/ } from "react";
import React, { Suspense, lazy, useState, useEffect /*, useContext*/ } from "react";
import LoadingScreen from "./loading-screen";
import * as API from "../../api/requests";
// optum ui toolkit
import { Notification } from "@uitk/react";

const PDFViewer = lazy(() => import("../pdf-viewer/pdf-viewer"));

const LOADING_STATES = {
  LOADING: 0,
  LOADED: 1,
  ERROR: 2,
};

type PdfLoaderProps = {
  documentId: string;
};

function PdfLoader({ documentId }: PdfLoaderProps): React.JSX.Element {
  const [documentUrl, setDocumentUrl] = useState<string>("");
  const [loadingState, setLoadingState] = useState(LOADING_STATES.LOADING);

  useEffect(() => {
    if (!documentId.length || loadingState !== LOADING_STATES.LOADING) return;
    API.getPdfUrl(documentId)
      .then((response) => {
        setDocumentUrl(() => {
          setLoadingState(LOADING_STATES.LOADED);
          return response.data.url;
        });
      })
      .catch(() => {
        setLoadingState(LOADING_STATES.ERROR);
      });
  }, [documentId, loadingState]);

  switch (loadingState) {
    case LOADING_STATES.ERROR:
      return (
        <div className="vertical-flex-center flex-grow">
          <Notification variant={"error"}>
            There is no work image item available. Please proceed to reject the work item.
          </Notification>
        </div>
      );
    case LOADING_STATES.LOADED:
      return (
        <Suspense fallback={<LoadingScreen />}>
          <PDFViewer url={documentUrl} />
        </Suspense>
      );
    case LOADING_STATES.LOADING:
    default:
      return <LoadingScreen />;
  }
}

export default PdfLoader;
