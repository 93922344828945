import { styled } from '@mui/system';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export const StyledAccordionSummary = styled(AccordionSummary)({
    '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: 0,
    },
});

export const StyledAccordionDetails = styled(AccordionDetails)({
    paddingTop: 0,
});
