import { SEARCH_ACTION, SearchAction } from "./search-actions";
import { SearchState } from "./search-state";
import { SearchRunner } from "../../utils/search";
import { logger } from "@optum-ai-charts/chart-components";

const SearchReducer = (state: SearchState, action: SearchAction): SearchState => {
  let newState: SearchState = state;
  switch (action.type) {
    case SEARCH_ACTION.ADD_INDEX:
      const newSource = SearchRunner.makeSource(action.payload.index);
      if (newSource) {
        newState = {
          ...state,
          sources: [...state.sources, newSource],
        };
      }
      break;
    case SEARCH_ACTION.SET_INDEX_STATUS:
      newState = {
        ...state,
        indexStatus: action.payload.status,
      };
      break;
    case SEARCH_ACTION.NEW_SEARCH:
      newState = {
        ...state,
        searchCount: state.searchCount + 1,
        searchType: action.payload.type,
        searchTier: action.payload.tier || "",
        searchTerms: action.payload.terms,
        searchResults: action.payload.hits,
      };
      break;
    case SEARCH_ACTION.CLEAR_RESULTS:
      newState = {
        ...state,
        searchTerms: [],
        searchResults: [],
      };
      break;
    default:
      // @ts-ignore
      throw new Error(`Unknown SEARCH action = ${action.type}`);
  }
  logger.debug(`new search state after action ${action.type}`);
  logger.debug(newState);
  return newState;
};

export default SearchReducer;
