import React from "react";

import "./loading-screen.scss";

export default function LoadingScreen(): React.JSX.Element {
  return (
    <main>
      <div className="chart-container vertical-flex-center loading-screen">
        <h1>Loading the chart, please wait...</h1>
        <div className="sk-chase">
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
          <div className="sk-chase-dot" />
        </div>
      </div>
    </main>
  );
}
