import { HEDIS_ACTION, HedisAction } from "./hedis-actions";
import { HedisState } from "./hedis-state";
import { logger } from "@optum-ai-charts/chart-components";

// Member verification status of both the entire document, and each page.
export const enum MEMBER_MATCH_STATUS {
  UNLOADED,
  UNKNOWN, // pipeline could not verify name and DOB info on this page
  REVIEWED,
  CONFIRMED,
  // SUSPICIOUS = 4, // should not happen
}

const HedisReducer = (state: HedisState, action: HedisAction): HedisState => {
  let newState = state;
  switch (action.type) {
    case HEDIS_ACTION.SET_CHASE_ID:
      newState = {
        ...state,
        chaseId: action.payload.chaseId,
      };
      break;
    case HEDIS_ACTION.SET_MEASURE:
      newState = {
        ...state,
        measure: action.payload.measure,
      };
      break;
    case HEDIS_ACTION.SET_TOKEN:
      newState = {
        ...state,
        authToken: action.payload.token,
      };
      break;
    case HEDIS_ACTION.SET_UNKNOWN_PAGES:
      newState = {
        ...state,
        unknownPages: action.payload.unknownPages,
        memberMatchStatus: action.payload.unknownPages.some((page) => !page.done)
          ? MEMBER_MATCH_STATUS.UNKNOWN
          : MEMBER_MATCH_STATUS.CONFIRMED,
      };
      logger.log(`set match status to ${newState.memberMatchStatus} SET_REVIEW_PAGES`);
      break;
    case HEDIS_ACTION.SET_SUSPICIOUS_PAGES:
      newState = {
        ...state,
        suspiciousPages: action.payload.suspiciousPages,
        memberMatchStatus: action.payload.suspiciousPages.some((page) => !page.done)
          ? MEMBER_MATCH_STATUS.UNKNOWN
          : MEMBER_MATCH_STATUS.CONFIRMED,
      };
      logger.log(`set match status to ${newState.memberMatchStatus} SET_REVIEW_PAGES`);
      break;
    case HEDIS_ACTION.CONFIRM_MEMBER_MATCH:
      newState = {
        ...state,
        suspiciousPages: state.suspiciousPages.map((page) => ({
          page: page.page,
          done: true,
        })),
        memberMatchStatus: MEMBER_MATCH_STATUS.CONFIRMED,
      };
      logger.log(`set match status to ${newState.memberMatchStatus} CONFIRM_MEMBER_MATCH`);
      break;
    case HEDIS_ACTION.CONFIRM_PAGE: {
      const newSuspiciousPages = state.suspiciousPages.map((page) => {
        if (page.page === action.payload.page) {
          return {
            page: page.page,
            done: true,
          };
        }
        return page;
      });

      newState = {
        ...state,
        suspiciousPages: newSuspiciousPages,
        memberMatchStatus: newSuspiciousPages.some((page) => !page.done)
          ? MEMBER_MATCH_STATUS.UNKNOWN
          : MEMBER_MATCH_STATUS.REVIEWED,
      };
      break;
    }
    case HEDIS_ACTION.CONFIRM_UNKNOWN_PAGE: {
      const newUnknownPages = state.unknownPages.map((page) => {
        if (page.page === action.payload.page) {
          return {
            page: page.page,
            done: true,
          };
        }
        return page;
      });
      newState = {
        ...state,
        unknownPages: newUnknownPages,
      };
      break;
    }
    case HEDIS_ACTION.SET_PATIENT_INFO:
      newState = {
        ...state,
        patientInfo: action.payload.info,
      };
      break;
    case HEDIS_ACTION.SET_HEALTH_PLAN:
      newState = {
        ...state,
        healthPlan: action.payload.healthPlan,
      };
      break;
    case HEDIS_ACTION.SET_SEARCH_INFO:
      newState = {
        ...state,
        searchId: action.payload.id,
        searchStyle: action.payload.styleName,
      };
      break;
    // case HEDIS_ACTION.SET_INCLUSION_INFO:
    //   newState = {
    //     ...state,
    //     inclusionsId: action.id,
    //     inclusionsCount: action.count,
    //   };
    //   break;
    // case HEDIS_ACTION.SET_EXCLUSION_INFO:
    //   newState = {
    //     ...state,
    //     exclusionsId: action.id,
    //     exclusionsCount: action.count,
    //   };
    //   break;
    // case HEDIS_ACTION.SET_CONTRAINDICATION_INFO:
    //   newState = {
    //     ...state,
    //     contraindicationsId: action.id,
    //     contraindicationsCount: action.count,
    //   };
    //   break;
    case HEDIS_ACTION.SET_TIERS_MAP:
      newState = {
        ...state,
        tiersMap: action.payload.tiers,
      };
      break;
    case HEDIS_ACTION.SET_EXCLUSIONS:
      newState = {
        ...state,
        exclusions: action.payload.exclusions,
      };
      break;
    case HEDIS_ACTION.SET_CONTRAINDICATIONS:
      newState = {
        ...state,
        contraindications: action.payload.contraindications,
      };
      break;
    case HEDIS_ACTION.SET_DISPLAY_PDF:
      newState = {
        ...state,
        display_as_pdf: action.payload.display_as_pdf,
      };
      break;
    default:
      // @ts-ignore
      throw new Error(`Unknown HEDIS action = ${action.type}`);
  }
  logger.debug(`hedis action ${action.type} executed, new state:`);
  logger.debug(newState);
  return newState;
};

export default HedisReducer;
