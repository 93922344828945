/**
 * @file Wrap children components in a React error boundary to handle exceptions
 * @author tpederson
 * @see https://codepen.io/gaearon/pen/wqvxGa?editors=0010
 */

import React, { ErrorInfo } from 'react';
import WarningIcon from '../../assets/icons/red-exclamation.svg';

type ErrorBoundaryProps = {
    children?: React.ReactNode;
};

type ErrorBoundaryState = {
    error: Error | null;
    errorInfo: ErrorInfo | null;
};

export default class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error,
            errorInfo,
        });
      // eslint-disable-next-line no-console
      console.error(
            JSON.stringify({
                message: 'Error boundary caught exception',
                error,
                stack: errorInfo.componentStack,
            })
        );
    }

    render(): React.JSX.Element | React.ReactNode {
        const { error, errorInfo } = this.state;
        if (errorInfo) {
            // Error path
            return (
                <div style={{ margin: 'auto' }}>
                    <img
                        className="large-warning-icon"
                        src={WarningIcon}
                        alt="Error"
                    />
                    <h2>Something went wrong.</h2>
                    <p>
                        You will need to re-load the page. This error has been
                        logged.
                    </p>
                    <p>
                        You can also report this issue. Cutting and pasting the
                        details is helpful.{' '}
                    </p>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {error && error.toString()}
                        <br />
                        {errorInfo.componentStack}
                    </details>
                </div>
            );
        }
        const { children } = this.props;
        return children;
    }
}
