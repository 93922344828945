import { API_URL_PREFIXES } from "./proxy-prefixes";
import { createAPI } from "./factory";
import {
  Api,
  HttpResponse,
  Document,
  PageDimensions,
  PagesText,
  PageBoxes,
  SearchIndexes,
  Terms,
  Tiers,
  Version,
} from "./open-api/generated/types";
import PageWordIndex from "../search/page-word-index";
import { SearchResult } from "../utils/search";
import { logger } from "@optum-ai-charts/chart-components";

let hedisAPI: Api<unknown> | null = null;
let baseUrl;

if (process.env.REACT_APP_USE_LINK_SUFFIX === "TRUE") {
  baseUrl = `${API_URL_PREFIXES.ARCH_LINK_SUFFIX}${API_URL_PREFIXES.PREFIX_HEDIS_API_URL}`;
} else {
  baseUrl = API_URL_PREFIXES.PREFIX_HEDIS_API_URL;
}

export const instantiateApi = (token: string): void => {
  hedisAPI = createAPI({
    baseUrl,
    baseApiParams: {
      headers: {
        jwt: `${token}`,
      },
    },
  });
};

/*
    CODING SESSION REQUESTS
 */

export const getApiVersion = (
  ...params: Parameters<typeof Api.prototype.version.getVersion>
): Promise<HttpResponse<Version>> => {
  logger.log(`GET api version`);
  return hedisAPI!.version.getVersion(...params);
};

export const getDocumentUsingJwt = (
  ...params: Parameters<typeof Api.prototype.document.getDocument>
): Promise<HttpResponse<Document>> => {
  logger.log(`GET coding session from chase ID = ${params[0]}`);
  return hedisAPI!.document.getDocument(...params);
};

export const getDocumentSearchIndexes = (
  ...params: Parameters<typeof Api.prototype.document.getDocumentSearchIndexes>
): Promise<HttpResponse<SearchIndexes>> => {
  logger.log(`GET search indexes for document,  ID = ${params[0]}`);
  return hedisAPI!.document.getDocumentSearchIndexes(...params);
};

export const getPagesText = (
  ...params: Parameters<typeof Api.prototype.document.getPagesText>
): Promise<HttpResponse<PagesText>> => {
  logger.log(`GET document text for pages [${params[1].start}..${params[1].start + params[1].limit}]`);
  return hedisAPI!.document.getPagesText(...params);
};

export const getPageBoxes = (
  ...params: Parameters<typeof Api.prototype.document.getDocumentPageBoxes>
): Promise<HttpResponse<PageBoxes>> => {
  logger.log(`GET document bounding boxes for pages [${params[1].start}..${params[1].start + params[1].limit}]`);
  return hedisAPI!.document.getDocumentPageBoxes(...params);
};

export const getPageWordIndex = async (docId: string, searchHit: SearchResult): Promise<PageWordIndex> => {
  const response = await getPagesText(docId, {
    start: searchHit.page,
    limit: 1,
  });
  return new PageWordIndex(response.data[0], searchHit);
};

export const getPageDimensions = (
  ...params: Parameters<typeof Api.prototype.document.getDocumentPageDimensions>
): Promise<HttpResponse<PageDimensions>> => {
  logger.log(`GET dimensions for pages [${params[1].start}..${params[1].start + params[1].limit}}`);
  return hedisAPI!.document.getDocumentPageDimensions(...params);
};

/*
    KEYWORDS REQUESTS
 */
export const getKeywordTiers = (
  ...params: Parameters<typeof Api.prototype.keywords.getKeywordTiers>
): Promise<HttpResponse<Tiers>> => {
  logger.log(`get tiers for measure ${params[0]}`);
  return hedisAPI!.keywords.getKeywordTiers(...params);
};

export const getKeywordTerms = (
  ...params: Parameters<typeof Api.prototype.keywords.getKeywordTerms>
): Promise<HttpResponse<Terms>> => {
  logger.log(`get terms for measure ${params[0]}`);
  return hedisAPI!.keywords.getKeywordTerms(...params);
};

export const getPdfUrl = (
  ...params: Parameters<typeof Api.prototype.document.getChartPdf>
): Promise<HttpResponse<any>> => {
  logger.log(`get pdfurl for ${params[0]}`);
  return hedisAPI!.document.getChartPdf(...params);
};
