import Fuse from "fuse.js";
import { SearchSource } from "./search-source";
import { AutoCompletion, SearchResult } from "./types";
import { logger } from "@optum-ai-charts/chart-components";
import { SearchRunnerOptions } from "./search-runner";

export class FuseSource extends SearchSource {
  protected deserialize(serializedIndex: string): Fuse<any> {
    const records = JSON.parse(serializedIndex);
    const options = {
      includeMatches: true,
      findAllMatches: true,
      minMatchCharLength: 2,
      threshold: 0.0,
      ignoreLocation: true,
      keys: ["query"],
    };
    logger.debug("got the model queries:");
    logger.debug(records);
    return new Fuse(records, options);
  }

  public autocomplete(query: string): AutoCompletion[] {
    const results: AutoCompletion[] = [];
    const fuseRecords = this.index.search(query);
    const addedQueries = new Set<string>();

    fuseRecords.forEach((record: any) => {
      const result = record.item;
      if (!addedQueries.has(result.query)) {
        addedQueries.add(result.query);
        results.push({
          text: result.query,
          source: this.id,
        });
      }
    });
    return results;
  }

  runQuery(query: string, options: SearchRunnerOptions): SearchResult[] {
    let rankingScores: SearchResult[] = [];
    const results: SearchResult[] = [];
    const fuseRecords = this.index.search(query);
    logger.debug(`results for query ${query} on source ${this.source} (${options.exactMatches})`);
    logger.debug(fuseRecords);
    const pagesWithMaches: Set<number> = new Set();

    fuseRecords.forEach((record: any) => {
      const result = record.item;
      // if uniqueResults === true, we are auto-completing. For now, assume only fuse index is model query, return the query
      if (result.query === query) {
        if (rankingScores.length === 0 && result.ranking_scores) {
          rankingScores = result.ranking_scores
            .slice(1, result.ranking_scores.length - 1)
            .split(",")
            .map((score: string, idx: number) => {
              return {
                page: idx,
                span: [],
                text: "",
                score: parseFloat(score),
                source: this.id,
              };
            });
        }
        for (const match of result.matches || []) {
          const [startChar, endChar] = match.span[0];
          const matchText = match.value;
          pagesWithMaches.add(match.page_no);
          results.push({
            page: match.page_no,
            // search results use lunr format, which is start char of span, and number of characters
            span: [startChar, endChar],
            text: matchText,
            score: rankingScores[match.page_no].score,
            source: this.id,
          });
        }
      }
    });
    results.push(...rankingScores.filter((score) => !pagesWithMaches.has(score.page)));
    return results;
  }
}
