/**
 * @file Adds a global keydown event listener
 * @author tpederson
 */
import { useEffect } from 'react';

const useKeyboardShortcuts = (
    callback: (event: KeyboardEvent) => boolean,
    allowShortcutsInInputs: boolean = false
): void => {
    useEffect(() => {
        function handleKeyDown(e: KeyboardEvent): any {
            if (e.target instanceof Element) {
                if (
                    !allowShortcutsInInputs &&
                    ['INPUT', 'TEXTAREA'].includes(e.target?.tagName) &&
                    e.type === 'text'
                ) {
                    return;
                }
                if (callback(e)) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown, true);
        return () => {
            window.removeEventListener('keydown', handleKeyDown, true);
        };
    }, [callback, allowShortcutsInInputs]);
};

export default useKeyboardShortcuts;
