// import { Review } from '../../components/member-match/types';
// import { Patient } from '../../api/open-api/api-generated';

import { SearchIndex } from "../../api/open-api/generated/types";
import { IndexStatus, SearchType } from "./search-state";
import { SearchResult } from "../../utils/search";

export const enum SEARCH_ACTION {
  ADD_INDEX,
  SET_INDEX_STATUS,
  NEW_SEARCH,
  CLEAR_RESULTS,
}

export interface AddIndexAction {
  type: SEARCH_ACTION.ADD_INDEX;
  payload: {
    index: SearchIndex;
  };
}

export const addIndex = (index: SearchIndex): AddIndexAction => {
  return {
    type: SEARCH_ACTION.ADD_INDEX,
    payload: { index },
  };
};

export interface SetIndexStatusAction {
  type: SEARCH_ACTION.SET_INDEX_STATUS;
  payload: {
    status: IndexStatus;
  };
}

export const setIndexStatus = (status: IndexStatus): SetIndexStatusAction => {
  return {
    type: SEARCH_ACTION.SET_INDEX_STATUS,
    payload: { status },
  };
};

export interface NewSearchAction {
  type: SEARCH_ACTION.NEW_SEARCH;
  payload: {
    type: SearchType;
    tier?: string;
    terms: string[];
    hits: SearchResult[];
  };
}

export const newSearch = (type: SearchType, terms: string[], hits: SearchResult[], tier?: string): NewSearchAction => {
  return {
    type: SEARCH_ACTION.NEW_SEARCH,
    payload: { type, tier, terms, hits },
  };
};

export interface ClearResultsAction {
  type: SEARCH_ACTION.CLEAR_RESULTS;
}

export const clearResults = (): ClearResultsAction => {
  return {
    type: SEARCH_ACTION.CLEAR_RESULTS,
  };
};

export type SearchAction = AddIndexAction | SetIndexStatusAction | NewSearchAction | ClearResultsAction;
