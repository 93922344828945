interface IKey {
  [key: number]: string;
}

const errorStatusCodes: IKey = {
  400: 'Bad Request. Invalid ID supplied',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  406: 'Not Acceptable',
  429: 'Too Many Requests',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
};

const hasStatusError = (status: number): string => errorStatusCodes[status];

export default hasStatusError;
