import lunr from "lunr";
import { SearchSource } from "../search-source";
import { AutoCompletion, SearchResult } from "../types";
import { logger } from "@optum-ai-charts/chart-components";
import { escapeQuery, hitsAny, hitsExact, LunrHit, LunrTerm, queryStartsWith, uniqueTerms } from "./lunr-helpers";
import { SearchRunnerOptions } from "../search-runner";

export class LunrSource extends SearchSource {
  protected deserialize(serializedIndex: string): lunr.Index {
    return lunr.Index.load(JSON.parse(serializedIndex));
  }

  /**
   * Prepares a query for lunr-specific search. Escapes characters that have special meaning in lunr.
   * Adds the '*' wildcard character as a suffix, so that "no" will return hits on "nope", "nobody", etc..
   * @param query The string to search for
   * @return string
   * @see {@link https://lunrjs.com/guides/searching.html} for more info
   * @protected
   */
  protected lunrizeQuery(query: string): string {
    const escapedQuery = query.replace(/[:~\-+^*\\]/g, "\\$&");
    return escapedQuery.trim() + "*";
  }

  autocomplete(query: string): AutoCompletion[] {
    const lunrQuery = queryStartsWith(escapeQuery(query.trim()));
    const hits = uniqueTerms(lunrQuery, this.index);
    logger.log(`results for autocompletions ${query} on source ${this.source}`);
    logger.log(hits);

    return hits.map((record: LunrTerm) => {
      return {
        text: this.textForField(record.term, record.field, record.ref),
        source: this.source,
      };
    });
  }

  protected textForField(term: string, field: string, recordRef: string): string {
    switch (field) {
      case "tier":
      case "keyword":
        return field === "keyword" ? recordRef : `All terms in ${recordRef}`;
      default:
      case "text":
        // these are document hits, use the term
        return term;
    }
  }

  runQuery(query: string, options: SearchRunnerOptions): SearchResult[] {
    let hits: LunrHit[];
    let lunrQuery = escapeQuery(query.trim());
    if (options.exactMatches) {
      hits = hitsExact(lunrQuery, this.index);
      logger.log(`results for hitsExact ${query} on source ${this.source}`);
    } else {
      lunrQuery = queryStartsWith(lunrQuery);
      hits = hitsAny(lunrQuery, this.index);
      logger.log(`results for hitsAny ${query} on source ${this.source}`);
    }
    return hits.map((record: LunrHit) => {
      return {
        page: record.page,
        span: record.span,
        text: this.textForField(record.term, record.field, record.ref),
        source: this.source,
      };
    });
  }
}
