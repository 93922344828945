/**
 * The app sends http requests based off of these prefixes.
 *
 * The node server uses proxy middleware to configure and use these prefixes to forward
 * the requests from the app to the correct entity, such as the coding app backend
 * (hosted in AWS labmda).
 */

module.exports.API_URL_PREFIXES = {
    PREFIX_S3_URL: '/s3-image-proxy-url',
    PREFIX_HEDIS_API_URL: '/hedis-api-proxy-url',
    ARCH_LINK_SUFFIX: `/${process.env.REACT_APP_LINK_SUFFIX}`,
};
