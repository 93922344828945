import { SearchResult, SearchSource } from '../../utils/search';

export enum SearchType {
  RAW = 'Raw',
  DOCUMENT_HIT = 'Document',
  KEYWORD = 'Keyword',
  EXCLUSION = 'Exclusion',
  CONTRAINDICATION = 'Unacceptable term',
  TIER = 'Tier',
  PRE_COMPUTED = 'Pre-Computed',
  MEMBER_MATCH =  'Member match',
}

export enum IndexStatus {
  ERROR = -1,
  UNINITIALIZED,
  LOADING,
  LOADED,
}

export type SearchState = {
  indexStatus: IndexStatus;
  sources: SearchSource[];

  searchCount: number;
  searchType: SearchType;
  searchTier: string;
  searchTerms: string[];
  searchResults: SearchResult[];
};
