export type LunrTerm = {
  term: string;
  field: string;
  ref: string;
};

export type LunrHit = {
  term: string;
  field: string;
  ref: string;
  page: number;
  span: number[];
};

export function escapeQuery(query: string): string {
  return query.replace(/[:~\-+^*\\]/g, "\\$&");
}

export function queryStartsWith(query: string): string {
  return "*" + query + "*";
}

export function uniqueTerms(query: string, index: lunr.Index): LunrTerm[] {
  const lunrRecords = index.search(query);
  const terms = new Set<string>();
  const results: LunrTerm[] = [];

  lunrRecords.forEach((record: any) => {
    const data = record.matchData.metadata as Record<string, any>;
    Object.keys(data).forEach((term) => {
      Object.keys(data[term]).forEach((field) => {
        if (data[term][field].position) {
          data[term][field].position.forEach(() => {
            const uniqueKey = `${term}_${field}_${record.ref}`;
            if (!terms.has(uniqueKey)) {
              results.push({
                term,
                field,
                ref: record.ref,
              });
              terms.add(uniqueKey);
            }
          });
        }
      });
    });
  });
  return results;
}

export function hitsAny(query: string, index: lunr.Index): LunrHit[] {
  const lunrRecords = index.search(query);
  const results: LunrHit[] = [];

  lunrRecords.forEach((record: any) => {
    type looseObject = Record<string, any>;
    const data = record.matchData.metadata as looseObject;
    Object.keys(data).forEach((term) => {
      // logger.log(`process hit ${term}`);
      Object.keys(data[term]).forEach((field) => {
        if (data[term][field].position) {
          data[term][field].position.forEach((position: number[]) => {
            // if (!terms.has(term)) {
            results.push({
              page: +record.ref,
              span: [position[0], position[0] + term.split(" ").length],
              term,
              ref: record.ref,
              field,
            });
          });
        }
      });
    });
  });
  return results;
}

export function hitsExact(query: string, index: lunr.Index): LunrHit[] {
  const lunrRecords = index.search(query);
  const results: LunrHit[] = [];

  const words = query.split(/\s+/).map((word) => word.toLowerCase());
  lunrRecords.forEach((result) => {
    let currentPositions: number[][] = [];
    let isValidPhrase = true;

    for (let i = 0; i < words.length; i++) {
      // @ts-ignore
      const termPositions = result.matchData.metadata[words[i]]?.text?.position;
      if (!termPositions) {
        isValidPhrase = false;
        break;
      }

      if (i === 0) {
        currentPositions = termPositions;
      } else {
        currentPositions = currentPositions.filter((pos) => {
          const nextPos = termPositions.find((next: number[]) => next[0] === pos[0] + pos[1] + 1);
          return nextPos !== undefined;
        });

        if (currentPositions.length === 0) {
          isValidPhrase = false;
          break;
        }
      }
    }

    if (isValidPhrase && currentPositions.length > 0) {
      currentPositions.forEach((pos) => {
        results.push({
          page: parseInt(result.ref),
          span: [pos[0], pos[0] + words.join(" ").length],
          term: query,
          field: "text",
          ref: result.ref,
        });
      });
    }
  });
  return results;
}
