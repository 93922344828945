import React, { forwardRef, useState, useEffect } from "react";
import { Card, CardContent, CardActionArea, Typography } from "@mui/material";
import { API_URL_PREFIXES } from "../api/proxy-prefixes";
import { SearchResult } from "../utils/search";
import { logger, paginatedGets } from "@optum-ai-charts/chart-components";
import PageWordIndex from "./page-word-index";
import { PageText } from "../api/open-api/generated/types";
import { useHedisContext } from "../providers/hedis";

type TextMatchResultProps = {
  result: SearchResult;
  style: React.CSSProperties;
  chartId: string;
  currentPage: number;
  chartCount: number;
  onClickResult: (result: SearchResult) => void;
};

const TextMatchResult = forwardRef<HTMLDivElement, TextMatchResultProps>((props, ref) => {
  const { result, style, onClickResult, chartId, currentPage, chartCount } = props;
  const { authToken } = useHedisContext();
  const [preText, setPreText] = useState<string>(""); // result.getMatchContext(100);
  const [postText, setPostText] = useState<string>(""); // result.getMatchContext(100);
  const [rendered, setRendered] = useState<boolean>(false);

  useEffect(() => {
    if (!rendered && Math.abs(currentPage - result.page) <= 5) {
      setRendered(true);
      // TODO: can we use requests.ts here?
      const url = `${API_URL_PREFIXES.PREFIX_HEDIS_API_URL}/document/${chartId}/paginated/text`;
      Promise.all(paginatedGets([result.page], url, authToken, chartCount)).then((response: PageText[]) => {
        logger.debug(`Rendering search hit preview for page ${result.page} at pos=${result.span[0]}`);
        logger.debug(JSON.stringify(response));
        const index = new PageWordIndex(response[0], result);
        setPreText(index.preContext);
        setPostText(index.postContext);
      });
    }
  }, [authToken, currentPage, chartId, chartCount, result, rendered]);

  return (
    // TODO: figure out typescript forwarded ref type
    <Card ref={ref} style={style}>
      <CardActionArea onClick={() => onClickResult(result)}>
        <CardContent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: 12, fontWeight: "bold", display: "inline" }} gutterBottom>
              Page {result.page + 1}
            </Typography>
            {result.score !== undefined && (
              <Typography style={{ display: "inline", color: "#1595D0" }} sx={{ fontSize: 12 }} gutterBottom>
                Score: {result.score.toFixed(2)}
              </Typography>
            )}
          </div>
          <Typography style={{ display: "inline" }} sx={{ fontSize: 12 }} gutterBottom>
            {preText}
          </Typography>
          <Typography
            style={{ display: "inline" }}
            sx={{
              fontSize: 12,
              fontWeight: "bold",
              backgroundColor: "#eed202",
            }}
            gutterBottom
          >
            {result.text}
          </Typography>
          <Typography style={{ display: "inline" }} sx={{ fontSize: 12 }} gutterBottom>
            <span> </span>
            {postText}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
});

TextMatchResult.displayName = "TextMatchResult";

export default TextMatchResult;
