import { Api, ApiConfig } from "./open-api/generated/types";
import responseValidator from "./response-validator";

const fetchWithInterceptor = (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
  let method = "GET";

  if (init?.method) {
    method = init.method;
  }

  return fetch(input, init).then(async (response) => {
    if (method) {
      await responseValidator(response.clone(), method);
    }
    return response;
  });
};

export const createAPI = <SecurityDataType>(apiConfig: ApiConfig<SecurityDataType>): Api<SecurityDataType> => {
  return new Api({
    customFetch: fetchWithInterceptor,
    ...apiConfig,
  });
};
