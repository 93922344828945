import { IndexType } from "../../api/open-api/generated/types";

export type AutoCompletion = {
  text: string;
  source: string;
};

export type SearchResult = {
  text: string;
  source: string;
  page: number; // undefined for autocompletions
  span: number[]; // undefined for autocompletions and some model query results
  score?: number; // only defined for model query results
};

export enum IndexTypeExt {
  NotFound = "Not Found",
  KeywordInclusion = "KeywordInclusion",
  KeywordExclusion = "KeywordExclusion",
}

export type AutoCompletionType = IndexType | IndexTypeExt;
