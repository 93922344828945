/**
 * @file Context for HEDIS-specific aspects of the chart
 * @author tpederson
 */
import React, { createContext, useContext, useReducer, Dispatch } from "react";
import HedisReducer, { MEMBER_MATCH_STATUS } from "./hedis-reducer";
import { HedisAction } from "./hedis-actions";
import { HedisState } from "./hedis-state";

const initialHedisState: HedisState = {
  chaseId: "",
  measure: "",
  authToken: "",
  patientInfo: null,
  healthPlan: "",
  unknownPages: [],
  suspiciousPages: [],
  memberMatchStatus: MEMBER_MATCH_STATUS.UNLOADED,
  searchId: -1,
  searchStyle: "",
  inclusionsId: -1,
  inclusionsCount: -1,
  exclusionsId: -1,
  exclusionsCount: -1,
  contraindicationsId: -1,
  contraindicationsCount: -1,
  tiersMap: new Map(),
  exclusions: new Set(),
  contraindications: new Set(),
  display_as_pdf: false,
};

const HedisContext = createContext<HedisState | null>(null);
export const HedisDispatcher = createContext<Dispatch<HedisAction>>(() => null);

export const useHedisContext = (): HedisState => {
  const currentHedisContext = useContext(HedisContext);

  if (!currentHedisContext) {
    throw new Error(
      "useHedisContext has to be used within <HedisContext.Provider>"
    );
  }
  return currentHedisContext;
};

interface HedisProviderProps {
  children?: React.ReactNode;
}

// The context provider that provides many critical bits of info about the chart
export function HedisProvider(props: HedisProviderProps): React.JSX.Element {
  const [hedisState, dispatch] = useReducer(HedisReducer, initialHedisState);
  return (
    <HedisContext.Provider value={hedisState}>
      <HedisDispatcher.Provider value={dispatch}>
        {props.children}
      </HedisDispatcher.Provider>
    </HedisContext.Provider>
  );
}
