/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Document {
  /** @example "12345" */
  id: string;
  /** @example 10 */
  original_page_count: number;
  /** @example 14 */
  total_page_count: number;
  member?: Member;
  member_match_info?: MemberMatch;
  /** @example ["PPC"] */
  measures: string[];
  /**
   * @format date
   * @example "2020-01-01"
   */
  date: string;
  /** @example false */
  display_as_pdf: boolean;
}

export interface Error {
  /** @example 404 */
  code: string;
  /** @example "Not Found" */
  message: string;
}

export enum IndexType {
  ModelQuery = "ModelQuery",
  Tiers = "Tiers",
  Terms = "Terms",
  Document = "Document",
}

export interface MemberMatch {
  /**
   * ID of the patient
   * @format uuid
   */
  member_id: string;
  status: "confirmed" | "suspicious" | "unknown";
  /**
   * User-friendly note about the match status
   * @example "All pages are confirmed to belong to Jane Doe."
   */
  note?: string;
  suspicious_pages?: number[];
  unknown_pages?: number[];
}

export interface PageDimension {
  /** @min 0 */
  page: number;
  width: number;
  height: number;
}

export type PageDimensions = PageDimension[];

export interface PageText {
  /**
   * @min 0
   * @example 7
   */
  page: number;
  /** @example "This is the text on page 7 of the medical chart for Jane Doe." */
  text: string;
}

export type PagesText = PageText[];

export interface BoundingBoxes {
  /** @example 10 */
  page?: number;
  bounding_boxes?: number[][];
}

export type PageBoxes = BoundingBoxes[];

export interface Member {
  /**
   * @format uuid
   * @example "patient_494e7584-6934-4323-bf4e-5c98e38cd8f4"
   */
  id?: string;
  /** @example "Joan" */
  firstname?: string;
  /** @example "Doe" */
  lastname?: string;
  gender?: "F" | "Female" | "M" | "Male" | "U" | "Unknown";
  /**
   * Date of birth
   * @format date
   * @example "1999-12-31T00:00:00.000Z"
   */
  dob?: string;
}

export interface PresignedUrl {
  /** @format url */
  url: string;
  /**
   * expiration time in minutes
   * @example 60
   */
  expiration: number;
}

export interface SearchIndex {
  id: IndexType;
  /**
   * The engine that was used for serialization on the backend
   * @format string
   * @example "lunr.js"
   */
  engine: string;
  /**
   * The version of the engine used to serialize the document
   * @format string
   * @example "2.3.9"
   */
  version: string;
  /** A serialized string of the search index */
  index?: string;
  /**
   * Will be a pre-signed url to AWS S3 resource
   * @format url
   */
  "index-url"?: string;
  /**
   * Order results for this index should appear in search box, 0 = appears at the top, first
   * @min 0
   * @example 1
   */
  order: number;
  /**
   * Indicates whether index is to be used only to populate the auto-complete box in the UI
   * @default false
   * @example true
   */
  auto_complete_only?: boolean;
  /**
   * Description of the index
   * @example "Index of the APH measure terms"
   */
  description?: string;
}

export interface SearchIndexes {
  /**
   * @min 0
   * @example 4
   */
  count: number;
  indexes: SearchIndex[];
}

export interface Term {
  /** @format uuid */
  id: string;
  /** @example "COA" */
  measure: string;
  /** @example "discharge instructions" */
  text: string;
  type: "inclusion" | "exclusion" | "contraindication";
  /** @example "PPC1" */
  tier: string;
}

export type Terms = Term[];

export interface Tier {
  /** @example "TRC" */
  measure: string;
  /** @example "NIA1" */
  id: string;
  /**
   * any unique integer to sort by
   * @example 1
   */
  order: number;
  /** @example "Notification of Inpatient Admission" */
  name: string;
}

export type Tiers = Tier[];

export interface Version {
  /** @format version */
  version: string;
  /** @example "hedis-v3 crux" */
  app?: string;
  /** @example "PSQL" */
  dao_type?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://jigkz34yotpn7telf7h3mdbz6e0mwyyn.lambda-url.us-east-1.on.aws/";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: FormData) =>
      (Array.from(input.keys()) || []).reduce((formData, key) => {
        const property = input.get(key);
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;
      let embeddedErrorCode: boolean = false;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
                embeddedErrorCode = data.status_code !== undefined;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok || embeddedErrorCode) throw data;
      return data;
    });
  };
}

/**
 * @title Version 3 Data Contracts
 * @version 1.1.2
 * @baseUrl https://jigkz34yotpn7telf7h3mdbz6e0mwyyn.lambda-url.us-east-1.on.aws/
 *
 * Updated spec for HEDIS / ICS / Chart Viewer APIs
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  version = {
    /**
     * @description Retrieve the version information for the HEDIS API that client is connecting to
     *
     * @tags Server
     * @name GetVersion
     * @summary Get version of HEDIS API
     * @request GET:/version
     * @secure
     */
    getVersion: (params: RequestParams = {}) =>
      this.request<
        {
          /** @format version */
          version: string;
          /** @example "hedis-v3 crux" */
          app?: string;
          /** @example "PSQL" */
          dao_type?: string;
        },
        any
      >({
        path: `/version`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  document = {
    /**
     * @description The JWT must have the document ID encoded into it
     *
     * @tags Documents
     * @name GetDocument
     * @summary Get a document (chart) to be worked
     * @request GET:/document
     * @secure
     */
    getDocument: (params: RequestParams = {}) =>
      this.request<
        Document,
        {
          /** @example 404 */
          code: string;
          /** @example "Not Found" */
          message: string;
        }
      >({
        path: `/document`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve a set of serialized search indexes for the document
     *
     * @tags Documents
     * @name GetDocumentSearchIndexes
     * @summary Get the search indexes for the document
     * @request GET:/document/{docId}/search-indexes
     * @secure
     */
    getDocumentSearchIndexes: (docId: string, params: RequestParams = {}) =>
      this.request<
        SearchIndexes,
        {
          /** @example 404 */
          code: string;
          /** @example "Not Found" */
          message: string;
        }
      >({
        path: `/document/${docId}/search-indexes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get a pre-signed url to the pdf file for the document
     *
     * @tags Documents
     * @name GetChartPdf
     * @summary Get a pre-signed url to the pdf file for the document
     * @request GET:/document/{docId}/pdf-url
     * @secure
     */
    getChartPdf: (docId: string, params: RequestParams = {}) =>
      this.request<
        PresignedUrl,
        {
          /** @example 404 */
          code: string;
          /** @example "Not Found" */
          message: string;
        }
      >({
        path: `/document/${docId}/pdf-url`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve text for a range of pages
     *
     * @tags Paginated Document Resources
     * @name GetPagesText
     * @summary Get text
     * @request GET:/document/{docId}/paginated/text
     * @secure
     */
    getPagesText: (
      docId: string,
      query: {
        /**
         * First page to retrieve info for
         * @format int32
         * @min 1
         */
        start: number;
        /**
         * Number of pages to retrieve info for
         * @format int32
         * @min 1
         */
        limit: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PagesText,
        {
          /** @example 404 */
          code: string;
          /** @example "Not Found" */
          message: string;
        }
      >({
        path: `/document/${docId}/paginated/text`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve pre-signed AWS image URLS for a range of pages
     *
     * @tags Paginated Document Resources
     * @name GetPagesImageUrls
     * @summary Get Image URLs
     * @request GET:/document/{docId}/paginated/image-urls
     * @secure
     */
    getPagesImageUrls: (
      docId: string,
      query: {
        /**
         * First page to retrieve info for
         * @format int32
         * @min 1
         */
        start: number;
        /**
         * Number of pages to retrieve info for
         * @format int32
         * @min 1
         */
        limit: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /**
           * @min 0
           * @example 7
           */
          page: number;
          /** @format url */
          url: string;
          /**
           * expiration time in minutes
           * @example 60
           */
          expiration: number;
        },
        {
          /** @example 404 */
          code: string;
          /** @example "Not Found" */
          message: string;
        }
      >({
        path: `/document/${docId}/paginated/image-urls`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve the bounding boxes for a specified range of pages
     *
     * @tags Paginated Document Resources
     * @name GetDocumentPageBoxes
     * @summary Get bounding boxes
     * @request GET:/document/{docId}/paginated/bounding-boxes
     * @secure
     */
    getDocumentPageBoxes: (
      docId: string,
      query: {
        /**
         * First page to retrieve info for
         * @format int32
         * @min 1
         */
        start: number;
        /**
         * Number of pages to retrieve info for
         * @format int32
         * @min 1
         */
        limit: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PageBoxes,
        {
          /** @example 404 */
          code: string;
          /** @example "Not Found" */
          message: string;
        }
      >({
        path: `/document/${docId}/paginated/bounding-boxes`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve the dimensions for a specified range of pages
     *
     * @tags Paginated Document Resources
     * @name GetDocumentPageDimensions
     * @summary Get page dimensions
     * @request GET:/document/{docId}/paginated/dimensions
     * @secure
     */
    getDocumentPageDimensions: (
      docId: string,
      query: {
        /**
         * First page to retrieve info for
         * @format int32
         * @min 1
         */
        start: number;
        /**
         * Number of pages to retrieve info for
         * @format int32
         * @min 1
         */
        limit: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PageDimensions,
        {
          /** @example 404 */
          code: string;
          /** @example "Not Found" */
          message: string;
        }
      >({
        path: `/document/${docId}/paginated/dimensions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  keywords = {
    /**
     * @description Retrieve the ids and labels of all tiers for a given measure
     *
     * @tags Keywords
     * @name GetKeywordTiers
     * @summary Get abstraction tiers
     * @request GET:/keywords/{measure}/tiers
     * @secure
     */
    getKeywordTiers: (measure: string, params: RequestParams = {}) =>
      this.request<
        Tiers,
        {
          /** @example 404 */
          code: string;
          /** @example "Not Found" */
          message: string;
        }
      >({
        path: `/keywords/${measure}/tiers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve the set of keyword terms for given measure
     *
     * @tags Keywords
     * @name GetKeywordTerms
     * @summary Get abstraction terms
     * @request GET:/keywords/{measure}/terms
     * @secure
     */
    getKeywordTerms: (measure: string, params: RequestParams = {}) =>
      this.request<
        Terms,
        {
          /** @example 404 */
          code: string;
          /** @example "Not Found" */
          message: string;
        }
      >({
        path: `/keywords/${measure}/terms`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
