import React from 'react';
import { Box } from '@mui/material';

type TabPanelProps = {
    style: React.CSSProperties;
    value: number;
    index: number;
    children?: React.ReactNode;
};

export default function TabPanel(props: TabPanelProps): JSX.Element {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tab-panel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={props.style}
        >
            {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
        </div>
    );
}
