import React, { useState, useCallback } from 'react';
import {
    Box,
    Dialog,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';

import { KeyboardShortcuts } from '@optum-ai-charts/chart-components';
import useKeyboardShortcuts from '../../hooks/use-keyboard-shortcuts';
import CloseIcon from '../../assets/icons/close-x.svg';

function KeyboardSupport(): React.JSX.Element {
    const [showHelpDialog, setShowHelpDialog] = useState(false);

    const manageKeyboardHelp = useCallback(
        (e: KeyboardEvent) => {
            const { code, altKey } = e;
            if (code === 'KeyS' && altKey) {
                setShowHelpDialog(!showHelpDialog);
                return true;
            }
            return false;
        },
        [showHelpDialog]
    );

    useKeyboardShortcuts(manageKeyboardHelp);

    function createData(
        shortcut: string,
        description: string
    ): { shortcut: string; description: string } {
        return { shortcut, description };
    }

    const rows = [
        createData('Alt s', 'Show / hide shortcuts help'),
        createData('Alt +', 'Zoom in'),
        createData('Alt -', 'Zoom out'),
        createData('Alt /', 'Zoom to Fit'),
        createData('Alt [', 'Rotate counter-clockwise'),
        createData('Alt ]', 'Rotate clockwise'),
        createData('Alt -->', 'Next Page'),
        createData('Alt <--', 'Previous Page'),
        createData('Alt f', 'Search'),
        createData('Alt g', 'Next search result'),
        createData('Alt shift g', 'Previous search result'),
    ];

    return (
        <>
            <KeyboardShortcuts allowInInputs />
            <Dialog open={showHelpDialog}>
                <Box sx={{ pb: 3 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                p: 2,
                                color: '#0F0F59',
                                textTransform: 'uppercase',
                            }}
                            gutterBottom
                        >
                            Keyboard Shortcuts
                        </Typography>
                        <IconButton
                            sx={{ h: 16, w: 16, pr: 2 }}
                            onClick={() => setShowHelpDialog(false)}
                        >
                            <img
                                src={CloseIcon}
                                alt="close dialog"
                                height={16}
                                width={25}
                            />
                        </IconButton>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 400 }}
                            size="small"
                            aria-label="a dense table"
                        >
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.shortcut}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                { border: 0 },
                                        }}
                                    >
                                        <TableCell align="right">
                                            {row.shortcut}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.description}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Dialog>
        </>
    );
}

export default KeyboardSupport;
