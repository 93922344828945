import React, { useState, useEffect, useCallback, useContext, ReactNode } from "react";
import { ChartDispatcher, CHART_FEATURE, CHART_ACTION, logger } from "@optum-ai-charts/chart-components";
import { HedisDispatcher } from "../../providers/hedis";
import { SearchDispatcher, setIndexStatus, IndexStatus, addIndex } from "../../providers/search";
import { API_URL_PREFIXES } from "../../api/proxy-prefixes";
import { LOADING_STATES } from "./loading-states";
import LoadingScreen from "./loading-screen";
import * as API from "../../api/requests";
import { Document, PageDimensions } from "../../api/open-api/generated/types";

// optum ui toolkit
import { Notification } from "@uitk/react";

type ChartLoaderProps = {
  children?: ReactNode;
  document: Document;
  token: string;
};

const thumbnailDimensions = {
  width: 80,
  height: 100,
};

function CruxLoader({ children, document, token }: ChartLoaderProps): React.JSX.Element {
  const hedisDispatch = useContext(HedisDispatcher);
  const chartDispatch = useContext(ChartDispatcher);
  const searchDispatch = useContext(SearchDispatcher);
  const [loadingState, setLoadingState] = useState(LOADING_STATES.INITIAL);
  const [firstPages, setFirstPages] = useState<PageDimensions>([]);

  const configureChartSearch = useCallback(async () => {
    try {
      const indexesResponse = await API.getDocumentSearchIndexes(document.id);
      logger.log(indexesResponse);
      indexesResponse.data.indexes.forEach((index) => {
        if (index["index-url"]) {
          const documentIndexRecord = index;
          const s3url = new URL(index["index-url"]);
          s3url.hostname = API_URL_PREFIXES.PREFIX_S3_URL;
          fetch(s3url).then((indexResponse) => {
            indexResponse.json().then((documentIndex) => {
              documentIndexRecord.index = JSON.stringify(documentIndex);
              searchDispatch(addIndex(documentIndexRecord));
            });
          });
        } else {
          searchDispatch(addIndex(index));
        }
      });
      // make some throw-away small pagination calls to sanity check that these APIs are up and working
      await API.getPagesText(document.id, { start: 0, limit: 1 });
      await API.getPageBoxes(document.id, { start: 0, limit: 1 });

      searchDispatch(setIndexStatus(IndexStatus.LOADED));
    } catch (error) {
      logger.warn(`Search disabled, error getting indexes: ${error}`);
      searchDispatch(setIndexStatus(IndexStatus.ERROR));
    }
  }, [document.id, searchDispatch]);

  useEffect(() => {
    // We want to configure the chart-components module ASAP so it has the proxies and can make API calls
    const s3 =
      process.env.REACT_APP_USE_LINK_SUFFIX === "TRUE"
        ? `${API_URL_PREFIXES.ARCH_LINK_SUFFIX}${API_URL_PREFIXES.PREFIX_S3_URL}`
        : API_URL_PREFIXES.PREFIX_S3_URL;

    const api =
      process.env.REACT_APP_USE_LINK_SUFFIX === "TRUE"
        ? `${API_URL_PREFIXES.ARCH_LINK_SUFFIX}${API_URL_PREFIXES.PREFIX_HEDIS_API_URL}`
        : API_URL_PREFIXES.PREFIX_HEDIS_API_URL;

    // @ts-ignore
    chartDispatch({
      type: CHART_ACTION.CONFIGURE,
      proxies: {
        s3,
        api,
      },
      apiRoot: "document",
      token,
      // eslint-disable-next-line no-bitwise
      features: CHART_FEATURE.FIT_ON_CHART_LOAD | CHART_FEATURE.PAGE_LOADING_SPINNER,
    });
  }, [token, chartDispatch]);

  useEffect(() => {
    if (firstPages.length && document.id.length) {
      // calculate the average page dimensions from the first pages loaded
      let valid = 0;
      let totalWidth = 0;
      let totalHeight = 0;

      for (let i = 0; i < firstPages.length; i++) {
        if (firstPages[i].width > 0) {
          valid++;
          totalWidth += firstPages[i].width;
          totalHeight += firstPages[i].height;
        }
      }
      const pageDimensions = {
        width: Math.round(totalWidth / valid),
        height: Math.round(totalHeight / valid),
      };
      logger.log(`loading chart viewer with dimensions (${pageDimensions.width},${pageDimensions.height}`);
      // @ts-ignore
      chartDispatch({
        type: CHART_ACTION.LOAD_CHART,
        chartId: document.id,
        chartCount: document.total_page_count,
        originalPageCount: document.original_page_count,
        chartData: null,
        chartInfo: {
          ocrStatus: 1,
        },
        pageDimensions,
        preloadImageUrls: true,
        preloadThumbnailUrls: true,
        thumbnailDimensions,
      });
      // The UI can be shown to the user now, we don't need to wait for the search calls to finish.
      setLoadingState(LOADING_STATES.LOADED);
      configureChartSearch();
    }
  }, [
    firstPages,
    token,
    hedisDispatch,
    chartDispatch,
    document.id,
    document.total_page_count,
    document.original_page_count,
    configureChartSearch,
  ]);

  useEffect(() => {
    setLoadingState(LOADING_STATES.LOADING);
    API.getPageDimensions(document.id, {
      start: 0,
      limit: Math.min(document.total_page_count, 3),
    })
      .then((pageDimensions) => {
        logger.log("page dimensions");
        logger.log(pageDimensions.data);
        setFirstPages(pageDimensions.data);
      })
      .catch(() => {
        setLoadingState(LOADING_STATES.ERROR);
      });
  }, [document.id, document.total_page_count]);

  switch (loadingState) {
    case LOADING_STATES.ERROR:
      return (
        <div className="chart-container vertical-flex-center">
          <Notification variant={"error"}>
            There is no work image item available. Please proceed to reject the work item.
          </Notification>
        </div>
      );
    case LOADING_STATES.LOADED:
      return <>{children}</>;
    case LOADING_STATES.LOADING:
    default:
      return <LoadingScreen />;
  }
}

export default CruxLoader;
