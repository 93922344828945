import React, { useState, useContext, useEffect } from "react";

import { Typography, Popover, Button } from "@mui/material";
import packageJson from "../../../package.json";
import Schema from "../../api/open-api/generated/hedis-spec-v3.json";
import { API_URL_PREFIXES } from "../../api/proxy-prefixes";
import { ChartContext, getJson } from "@optum-ai-charts/chart-components";
import { getApiVersion } from "../../api/requests";

const Help = (): React.JSX.Element => {
  // @ts-ignore
  const { version: ChartVersion, chartId } = useContext(ChartContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [apiVersion, setApiVersion] = useState<string>("");
  const [nodeVersion, setNodeVersion] = useState<string>("");
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    let proxyVersionUrl;
    if (process.env.REACT_APP_USE_LINK_SUFFIX === "TRUE") {
      proxyVersionUrl = `${API_URL_PREFIXES.ARCH_LINK_SUFFIX}/proxyVersion`;
    } else {
      proxyVersionUrl = "/proxyVersion";
    }
    getJson(proxyVersionUrl)
      .then((response: any) => {
        setNodeVersion(response.version);
      })
      .catch(function () {
        setNodeVersion("Unknown");
      });

    getApiVersion()
      .then((response) => {
        setApiVersion(response.data.version);
      })
      .catch(() => {
        setApiVersion("Unknown");
      });
  }, []);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className="feedback-button"
        variant="text"
        onClick={handleClick}
        style={{ textTransform: "none", height: "30px" }}
        color="primary130"
        aria-describedby={id}
      >
        <Typography sx={{ fontSize: 14, fontWeight: 700, color: "#3262C7" }}>
          Version
        </Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography sx={{ padding: 2 }}>
          Front End: {packageJson.version}
          <br />
          HEDIS API: {apiVersion}
          <br />
          Proxy Server: {nodeVersion}
          <br />
          OpenAPI Schema: {Schema.info.version}
          <br />
          Charts Module: {ChartVersion}
          <br />
          Doc Id: {chartId}
        </Typography>
      </Popover>
    </div>
  );
};

export default Help;
