/**
 * @file Context for HEDIS-specific aspects of the chart
 * @author tpederson
 */
import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import SearchReducer from './search-reducer';
import { SearchAction } from './search-actions';
import { IndexStatus, SearchState, SearchType } from './search-state';

const initialSearchState: SearchState = {
  indexStatus: IndexStatus.UNINITIALIZED,
  sources: [],

  searchCount: 0,
  searchType: SearchType.KEYWORD,
  searchTier: '',
  searchTerms: [],
  searchResults: [],
};

export const SearchContext = createContext<SearchState | null>(null);
export const SearchDispatcher = createContext<Dispatch<SearchAction>>(() => null);

export const useSearchContext = (): SearchState => {
  const currentSearchContext = useContext(SearchContext);

  if (!currentSearchContext) {
    throw new Error('useSearchContext has to be used within <SearchContext.Provider>');
  }
  return currentSearchContext;
};

interface SearchProviderProps {
  children?: React.ReactNode;
}

// The context provider that provides many critical bits of info about the chart
export function SearchProvider(props: SearchProviderProps): React.JSX.Element {
  const [searchState, dispatch] = useReducer(SearchReducer, initialSearchState);
  return (
    <SearchContext.Provider value={searchState}>
      <SearchDispatcher.Provider value={dispatch}>{props.children}</SearchDispatcher.Provider>
    </SearchContext.Provider>
  );
}
